import React, { Component } from 'react';
import { deleteByQuery, getHitsFromQuery, getParamConfig, getUserToken, getWithExpiry, killProcess, setWithExpiry } from '../../utils/functions';
import "../../styles/MySpace.css";
import { MdDelete, MdEdit, MdEditNote } from 'react-icons/md';
import { VscServerProcess } from "react-icons/vsc";
import UpdateIndex from './UpdateIndex';
import { Autocomplete, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { CgTranscript } from "react-icons/cg";

class TableData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            indices: [],
            indice: "",
            open: false,
            currId: "",
            openDialog: false,
            currItem: null,
            deletedId: null
        }
        this.time = null
        this.updateData = this.updateData.bind(this)
        this.handleOpenDialog = this.handleOpenDialog.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
    }

    updateData() {
        if (this.state.indice) {
            clearInterval(this.time)
            const paramConfig = getParamConfig()
            const config = this.props.appConfig.find((app) => app.displayName === this.state.indice)
            const video_index = config && config["overviewIndex"] ? config["overviewIndex"] : ""
            const retin_index = config && config["index"] ? config["index"] : ""
            const query = {
                "sort": [{ "date_update": "desc" }, { "date_upload": "desc" },],
                "size": 200,
                "query": {
                    "exists": {
                        "field": "user_name"

                    }
                }
            }
            getHitsFromQuery(paramConfig["es_host"], video_index, paramConfig["es_credential"], query).then((hits) => {
                const data = hits.map((hit) => {
                    return { videoIndex: video_index, retinIndex: retin_index, id: hit._id, ...hit._source }
                })
                if (JSON.stringify(data) !== JSON.stringify(this.state.data) || this.state.loading) {
                    this.setState({ data: data, loading: false })
                }
                this.time = setInterval(this.updateData, 500)
            }).catch(err => {
                this.time = setInterval(this.updateData, 500)
                console.log(err)
            })
        }
    }

    handleDelete() {
        const { currId, indice } = this.state
        const config = this.props.appConfig.find((app) => app.displayName === indice)
        const video_index = config["overviewIndex"] ? config["overviewIndex"] : ""
        const retin_index = config["index"] ? config["index"] : ""

        this.setState({ open: false, deletedId: currId })
        const paramConfig = getParamConfig()
        const query1 = {
            "query": {
                "term": {
                    "_id": currId
                }
            }
        }
        if (video_index) {
            deleteByQuery(paramConfig["es_host"],
                video_index,
                paramConfig["es_credential"], query1).then((res) => {
                    if (res === 200) {
                        if (retin_index) {
                            const query2 = {
                                "query": {
                                    "match_phrase": {
                                        "idparent": currId
                                    }
                                }
                            }
                            deleteByQuery(paramConfig["es_host"],
                                retin_index,
                                paramConfig["es_credential"], query2).then((res) => {
                                    if (res === 200) {
                                        // kill process
                                        const req = { id: currId }
                                        killProcess(req).then((res) => {
                                            this.setState({ deletedId: null })
                                        }).catch((e) => {
                                            console.log("error :", e);
                                        })
                                    }
                                }).catch((e) => {
                                    console.log("error :", e);
                                });
                        }
                    }

                }).catch((e) => {
                    console.log("error :", e);
                });
        }
    }


    handleOpenDialog = (id) => {
        this.setState({ open: true, currId: id })
    }

    handleDialogClose = () => {
        this.setState({ open: false, openDialog: false })

    }


    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.appConfig) !== JSON.stringify(this.props.appConfig)) {

            const indices = this.props.appConfig.map((item) => item["displayName"])
            const indice = getWithExpiry("indice") ? getWithExpiry("indice") : indices.length > 0 ? indices[indices.length - 1] : ""

            setWithExpiry("indice", indice, 35940000)

            this.setState({
                indices: indices, indice: indice,
                loading: true,
                data: []

            });
        } else if (prevState.indice !== this.state.indice) {
            this.setState({ loading: true, data: [] })
        }


    }


    componentDidMount() {
        this.time = setInterval(this.updateData, 500)


        const indices = this.props.appConfig.map((item) => item["displayName"])
        const indice = getWithExpiry("indice") ? getWithExpiry("indice") : indices.length > 0 ? indices[indices.length - 1] : ""

        setWithExpiry("indice", indice, 35940000)

        this.setState({
            indices: indices, indice: indice

        });

    }

    componentWillUnmount() {
        clearInterval(this.time)
    }

    render() {
        const { data, loading, indices, indice, currItem, deletedId } = this.state;
        const userToken = getUserToken();
        return (
            <div className='table-data'>
                <Autocomplete
                    id="list-indices"
                    className='list-indices'
                    size="small"
                    options={indices}
                    getOptionLabel={(option) => option}
                    value={indice}
                    onChange={(event, newValue) => {
                        this.setState({ indice: newValue, loading: true, data: [] });
                        setWithExpiry("indice", newValue, 35940000)
                    }}
                    style={{ width: 300, marginBottom: 5 }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Client" placeholder="client" />
                    )}
                />
                <TableContainer component={Paper}>
                    {loading && <LinearProgress />}

                    {!loading &&
                        <Table className={"table"} aria-label="simple table">
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableCell>Nom</TableCell>
                                    <TableCell >Statut</TableCell>
                                    <TableCell >Télécharger par</TableCell>
                                    <TableCell >Date de mise à jour</TableCell>
                                    <TableCell >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='table-body'>
                                {data.map((row) => {
                                    if (row.id === "c24255b9bbc611640d2da92b3fd4fc109ea0438e")
                                        console.log(!row.synthpoint)
                                    const date_update = row.date_update ? row.date_update : row.date_upload
                                    const date = new Date(date_update);
                                    const formattedDate = date.toLocaleString();
                                    return (
                                        <TableRow key={row.id} className='table-row'>
                                            <TableCell className={"tabelCell"}>
                                                {row.title}
                                            </TableCell>
                                            <TableCell className={!["finish", "error"].includes(row.status) ? "tableCell-status" : ""}>{!["finish", "error",].includes(row.status) && <CircularProgress size={15} />} {row.status}</TableCell>
                                            <TableCell style={row.user_name === userToken["user_name"] ? { color: "#c3eeff" } : {}}>{row.user_name}</TableCell>
                                            <TableCell >{formattedDate}</TableCell>
                                            <TableCell ><ButtonGroup color="primary" aria-label="outlined primary button group">

                                                <Tooltip title="Editer la transcription" aria-label="edit"><IconButton color="primary" onClick={(e) => {
                                                    document.location.href = "/edit?i=" + row.id + "&c=" + indice;
                                                }
                                                } disabled={!["finish", "reformulation"].includes(row.status)}><MdEdit style={{ color: !["finish", "reformulation"].includes(row.status) ? "#EEEEEE" : "#A0DEFF" }} /></IconButton></Tooltip>
                                                <Tooltip title="Editer la synthèse" aria-label="edit note"><IconButton color="primary" onClick={(e) => document.location.href = "/synthesis?i=" + row.id + "&c=" + indice} disabled={row.status !== "finish" || !row.synthpoint}><MdEditNote style={{ color: row.status !== "finish" || !row.synthpoint ? "#EEEEEE" : "#A0DEFF" }} /></IconButton></Tooltip>

                                                {deletedId === row.id ? <CircularProgress size={15} /> : <Tooltip title="Supprimer" aria-label="delete"><IconButton onClick={(e) => this.handleOpenDialog(row.id)}><MdDelete style={{ color: "#F4676E" }} /></IconButton></Tooltip>}
                                                <Tooltip title="Mise à jour" aria-label="udpate"><IconButton onClick={(e) => this.setState({ openDialog: true, currItem: row })} disabled={!["finish", "error"].includes(row.status)}><VscServerProcess style={{ color: !["finish", "error"].includes(row.status) ? "#EEEEEE" : "#A0DEFF" }} /></IconButton></Tooltip>
                                               {row.synthpoint && <Tooltip title="Voir PV digital" aria-label="pv digit"><IconButton color="primary" onClick={(e) => window.open(getParamConfig("web_url")+"/pvdigit/?i=" + row.id + "&c=" + indice)}><CgTranscript style={{ color:"#A0DEFF" }} /></IconButton></Tooltip>}

                                            </ButtonGroup></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    }
                </TableContainer>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    aria-labelledby="dialog-delete"
                >
                    <DialogTitle id="dialog-delete">
                        Confirmation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Souhaitez-vous vraiment supprimer toutes les données du document ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleDialogClose}
                            className="button plain primaryMain"
                        >
                            Annuler
                        </Button>

                        <Button
                            onClick={this.handleDelete}
                            className="button plain bg-danger"
                        >
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
                <UpdateIndex openDialog={this.state.openDialog} handleClose={this.handleDialogClose} item={currItem} indice={indice} />
            </div>

        );
    }
}


export default TableData;