import React, { Component } from 'react';
import "../../styles/UpdateIndex.css"
import { generatePVDigital, getUserToken, updateIndex } from '../../utils/functions';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, styled } from '@mui/material';



const StyledBt = styled(Button)({
    textTransform: "none",
    backgroundColor: "#1f8299",
    color: "#fff",
    fontSize: "1rem",
    marginTop: "1em",
    "&:hover": {
        backgroundColor: "#8db2c2",
    }
})


class UpdateIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { checked: [], langue: "fr", title: "", description: "", index: "", id: "", time: 120, reformulation: "", pvdigit: false },

        }

        this.handleToggle = this.handleToggle.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleToggle = (value) => {
        const data = this.state.data;
        const currentIndex = data["checked"].indexOf(value);
        const newChecked = [...data["checked"]];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            data: { ...this.state.data, checked: newChecked },
        });
    };


    handleUpdate = () => {
        const userToken = getUserToken()
        let newData = this.state.data;
        newData["user"] = userToken["user_name"]
        newData["email"] = userToken["user_email"]
        updateIndex(newData).then(res => {
            console.log(res)
            //     // res["fileName"] = isYouTubeUrl(newData["fileName"]) ? newData["fileName"].split("?v=")[1] : newData["fileName"]
            //     // res["title"] = newData["title"]
            //     // const req = { user_name: userToken["user_name"], user_email: userToken["user_email"], message: JSON.stringify(res) }
            //     // sendMail(req).then(res => {
            //     //     console.log(res)
            //     // }).catch(err => { console.log(err) });
        }).catch(err => { console.log(err) });
        this.props.handleClose()

    }

    handleChange = (name, event) => {
        if (name === "reformulation") {
            const reformulation = this.state.data["reformulation"] === event.target.value ? "" : event.target.value;
            this.setState({
                data: { ...this.state.data, [name]: reformulation },
            });
        }
        else if (name === "pvdigit") {
            this.setState({
                data: { ...this.state.data, [name]: event.target.checked },
            });
        }

    };

    handleGeneratePVDigital = () => {
        this.props.handleClose()
        generatePVDigital()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item) {
            const { item, indice } = this.props
            const { data } = this.state
            this.setState({ data: { ...this.state.data, "id": item["id"], "title": item["title"], "description": item["description"], "langue": item["srt_lang"], "videoIndex": item["videoIndex"], "retinIndex": item["retinIndex"], "optionSeq": item["srt_speaker"] || data["checked"].indexOf(1) !== -1 ? "speaker" : "auto", indice: indice } });
        }

    }

    componentDidMount() {
        const { item, indice } = this.props
        if (item) {
            this.setState({ data: { ...this.state.data, "id": item["id"], "title": item["title"], "description": item["description"], "langue": item["srt_lang"], "videoIndex": item["videoIndex"], "retinIndex": item["retinIndex"], "optionSeq": item["srt_speaker"] ? "speaker" : "auto", indice: indice } });
        }
    }
    render() {
        const { openDialog, handleClose, item } = this.props;
        const { data } = this.state;
        // const { data } = this.state
        // const speakerExist = this.props.item && this.props.item["srt_speaker"]
        const label_ref = item && item["type_reformulation"] ? "Relancer la reforumulation existante (" + item["type_reformulation"] + ") :" : "Lancer une nouvelle reforumulation :"
        return (
            <div>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    className='dialog'
                    classes={{ paper: 'customDialogPaper' }}
                >
                    <DialogTitle id="alert-dialog-title">Mise à jour du fichier :  <span>{data["title"]}</span> </DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <FormLabel sx={{
                                color: "#fbfbfe"
                            }}>{label_ref}</FormLabel>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                className='radio-group'
                                value={this.state.data["reformulation"]}
                                onClick={(e) => this.handleChange("reformulation", e)}
                                row
                            >
                                <FormControlLabel className='radio-label' value="8" control={<Radio />} label="Transcription reformulée" />
                                <FormControlLabel className='radio-label' value="9" control={<Radio />} label="Compte rendu reformulé" />
                                <FormControlLabel className='radio-label' value="10" control={<Radio />} label="Synthèse" />
                            </RadioGroup>
                        </FormControl>


                        <FormControlLabel
                            label="Générer le PV digital"
                            control={
                                <Checkbox
                                    onClick={(e) => this.handleChange("pvdigit", e)}
                                />
                            }
                        />
                        

                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={4}>
                                <StyledBt onClick={this.handleUpdate} >
                                    Mise à jour
                                </StyledBt>
                            </Grid>
                            <Grid item xs={2}>
                                <StyledBt onClick={handleClose} autoFocus>
                                    Annuler
                                </StyledBt>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default UpdateIndex;