import React, { Component } from 'react';
import { deleteSeq, getKeywords, romanize, updateByQuery, updateSeq } from '../../../../utils/functions';
import "../../../../styles/AnnotationComponent.css"
import { MdAddCircle, MdDeleteOutline, MdOutlineSave } from 'react-icons/md';
import { RxReset } from "react-icons/rx";
import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, Switch, TextareaAutosize, TextField, Tooltip } from '@mui/material';

class AnnotationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textAll: "",
            sequence: { _source: {} },
            reformulation: "",
            listLoc: [],
            listPoint: [],
            listSubPoint: [],
            openMessage: { open: false, message: "" },
            open: false,
            updatedLoc: false,
            showResume: false,
            showReformulation: true,
            displayPointFilter: false,
            displaySubPointFilter: false,
            isEnded: false
        };
        this.timer = null
        this.updateText = this.updateText.bind(this)
        this.handleUpdateIndex = this.handleUpdateIndex.bind(this)
        this.handleEditText = this.handleEditText.bind(this)
        this.handleEditLoc = this.handleEditLoc.bind(this)
        this.handleOpenDialog = this.handleOpenDialog.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleCloseDialog = this.handleCloseDialog.bind(this)
        this.onClose = this.onClose.bind(this)
        this.handleShowResume = this.handleShowResume.bind(this)
        this.handleAddPoint = this.handleAddPoint.bind(this)
        this.handleAddSubPoint = this.handleAddSubPoint.bind(this)
        this.handleShowReformulation = this.handleShowReformulation.bind(this)
        this.handleReset = this.handleReset.bind(this)

    }

    handleCloseMessage = (event) => {

        this.setState({
            openMessage: { open: false, message: "" },
        });
    };

    handleShowResume() {
        this.setState({
            showResume: !this.state.showResume,
            showReformulation: false
        });
    }

    handleShowReformulation() {
        this.setState({
            showResume: false,
            showReformulation: !this.state.showReformulation,
        });
    }

    updateText = () => {
        const { playerRef } = this.props;
        const { sequence } = this.state;
        if (!playerRef.current || !playerRef.current.playlistManager?.currentVideo) return;

        const curSequence = playerRef.current.playlistManager.currentVideo;
        const isNewSequence =
            JSON.stringify(sequence) !== JSON.stringify(curSequence);
        if (isNewSequence) {
            // Gérer l'affichage des filtres
            const displayPointFilter = !!curSequence._source.point;
            const displaySubPointFilter = !!curSequence._source.subpoint;

            // Mettre à jour l'état
            this.setState({
                sequence: curSequence,
                reformulation: curSequence._source.reformulation || "",
                displayPointFilter,
                displaySubPointFilter,
                showReformulation: true,
            });

            // Appeler handleAddPoint si curSequence contient un point
            if (displayPointFilter) {
                this.handleAddPoint();
            }
        }
    };


    handleOpenDialog() {
        this.setState({
            open: true
        })

    }

    handleCloseDialog() {
        this.setState({
            open: false
        })
    }

    handleDelete() {
        const params = {
            es_index: this.props.es_index,
            id: this.state.sequence._source._id
        }
        deleteSeq(params).then(res => {
            if (res.status === 200) {
                this.props.deleteSequence(params.id)
                this.setState({ open: false, openMessage: { open: true, message: "Supprimé avec succès" } })

            } else {
                this.setState({ open: false, openMessage: { open: true, message: "Erreur :" + res.error } })
            }

        }).catch(err => {
            console.log(err)
            this.setState({ open: false, openMessage: { open: true, message: "Erreur :" + err } })
        })
    }


    handleUpdateIndex = async () => {
        const { sequence, reformulation, showReformulation } = this.state;
        const { playerRef, es_index, updateSequence, handleNextClip } = this.props;
        try {
            const curSequence = playerRef.current?.playlistManager.currentVideo
            if (JSON.stringify(sequence) !== JSON.stringify(curSequence) || !showReformulation || reformulation !== curSequence["_source"]["reformulation"]) {
                const reformulationUpdated = sequence["_source"]["reformulation"] && reformulation !== sequence["_source"]["reformulation"] ? true : false
                const typeExport = !showReformulation ? 0 : (sequence._source.textAll !== curSequence["_source"]["textAll"]) && !reformulationUpdated ? 0 : 1
                const req = {
                    es_index: es_index,
                    id: sequence._source._id,
                    data: JSON.stringify({ textAll: sequence._source.textAll, listloc: sequence._source.listloc, reformulation: reformulation, typeExport: typeExport }),
                };
                const res = await updateSeq(req);
                if (res.status === 200) {
                    const { idparent, ordresequence, point, subpoint, listloc } = sequence._source;
                    if (
                        curSequence?._source?.listloc &&
                        curSequence?._source?.listloc.includes("Speaker") &&
                        listloc &&
                        !listloc.includes("Speaker")
                    ) {
                        const speakerQuery = {
                            script: {
                                source: "ctx._source.listloc = params.speaker",
                                lang: "painless",
                                params: {
                                    speaker: listloc,
                                },
                            },
                            query: {
                                bool: {
                                    must: [
                                        { match_phrase: { idparent: idparent } },
                                        { match_phrase: { listloc: curSequence?._source?.listloc } },
                                    ],
                                },
                            },
                        };
                        await updateByQuery(es_index, speakerQuery);

                    }
                    if (point !== curSequence._source.point) {
                        const currPoint = curSequence._source.point ? curSequence._source.point : ""
                        const pointQuery = {
                            script: {
                                source: "ctx._source.point = params.point",
                                lang: "painless",
                                params: { point: point },
                            },
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "term": {
                                                "idparent": idparent
                                            }
                                        },
                                        {
                                            "range": {
                                                "ordresequence": {
                                                    "gte": ordresequence
                                                }
                                            }
                                        },
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "term": {
                                                            "point": currPoint
                                                        }
                                                    },
                                                    {
                                                        "bool": {
                                                            "must_not": {
                                                                "exists": {
                                                                    "field": "point"
                                                                }
                                                            }
                                                        }
                                                    }

                                                ]
                                            }
                                        }
                                    ]
                                }
                            }

                        };
                        await updateByQuery(es_index, pointQuery);
                    }
                    if (subpoint !== curSequence._source.subpoint) {
                        const curSubpoint = curSequence._source.subpoint ? curSequence._source.subpoint : ""

                        const subpointQuery = {
                            script: {
                                source: "ctx._source.subpoint = params.subpoint",
                                lang: "painless",
                                params: { subpoint: subpoint },
                            },
                            query: {
                                bool: {
                                    must: [
                                        { match_phrase: { idparent: idparent } },
                                        { match_phrase: { point: point } },
                                        { range: { ordresequence: { gte: ordresequence } } },
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "term": {
                                                            "subpoint": curSubpoint
                                                        }
                                                    },
                                                    {
                                                        "bool": {
                                                            "must_not": {
                                                                "exists": {
                                                                    "field": "subpoint"
                                                                }
                                                            }
                                                        }
                                                    }

                                                ]
                                            }
                                        }
                                    ],
                                },
                            },
                        };
                        await updateByQuery(es_index, subpointQuery);
                    }
                    this.setState({
                        openMessage: {
                            open: true,
                            message: "Mise à jour avec succès",
                        },
                    });

                    // const reformulationUpdated = sequence["_source"]["reformulation"] && reformulation !== sequence["_source"]["reformulation"] ? true : false

                    updateSequence(sequence);
                } else {
                    this.setState({
                        openMessage: {
                            open: true,
                            message: "Erreur : " + JSON.stringify(res),
                        },
                    });
                }
            } else {
                handleNextClip();
            }

        }
        catch (error) {
            this.setState({
                openMessage: {
                    open: true,
                    message: "Erreur : " + error,
                },
            });
            console.log("error :", error);
        }


    };

    handleEditText(event, key) {
        const value = event.target.value
        if (key === "reformulation") {
            this.setState({ reformulation: value })
        } else {
            this.setState({
                sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], [key]: value } }
            })
        }

        // const regex = new RegExp(`(${"Sandra"})`, 'gi');

        // const text = event.target.value.split(regex).map((part, index) => (
        //     regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
        // ));
        // this.setState({
        //     sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], textAll: event.target.value } }
        // })
        // this.setState({
        //     sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], textAll: value } }
        // })
    }


    handleEditLoc(newValue) {
        this.setState({
            sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], listloc: newValue } }
        })
    }


    handleEditPoint(newValue, reason) {
        if (reason === "clear") {
            this.setState({
                sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], point: "", subpoint: "" } },
                displayPointFilter: false,
                displaySubPointFilter: false
            })
        }
        else {
            const curSequence = this.props.playerRef.current?.playlistManager.currentVideo;
            const pIsChanged = curSequence && curSequence._source.point !== newValue
            this.setState({
                sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], point: newValue, subpoint: pIsChanged ? "" : curSequence["_source"]["subpoint"] } },
                displaySubPointFilter: pIsChanged ? false : curSequence["_source"]["subpoint"] ? true : this.state.displaySubPointFilter
            })
        }

    }


    handleEditSubPoint(newValue, reason) {
        if (reason === "clear") {
            this.setState({
                sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], subpoint: "", displaySubPointFilter: false } }
            })
        } else {
            this.setState({
                sequence: { ...this.state.sequence, _source: { ...this.state.sequence["_source"], subpoint: newValue } }
            })
        }
    }

    handleAddPoint() {
        this.setState({
            displayPointFilter: true
        })
    }

    handleAddSubPoint() {
        this.setState({
            displaySubPointFilter: true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { id, es_index, playerRef } = this.props
        const { listLoc, listPoint, listSubPoint } = this.state
        playerRef.current?.playlistManager.videoController.addEventListener("timeupdate", async () => {
            const currentTime = playerRef.current?.playlistManager.getCurrentTime();
            if (currentTime <= 0.5) {
                this.updateText()
            }
        });

        const query = {
            "term": {
                "idparent": id
            }

        }
        getKeywords(es_index, query, "listloc").then(res => {
            const newListLoc = res.map(item => item.key)
            if (JSON.stringify(listLoc) !== JSON.stringify(newListLoc))
                this.setState({
                    listLoc: newListLoc
                })
        }).catch(err => {
            console.log(err)
        })

        getKeywords(es_index, query, "point").then(res => {
            const newListPoint = res.map(item => item.key)
            if (JSON.stringify(listPoint) !== JSON.stringify(newListPoint)) {
                this.setState({
                    listPoint: newListPoint
                })

            }
            const query2 = {
                "bool": {
                    "must": [
                        { "term": { "idparent": id } },
                        {
                            "term": { "point": this.state.sequence["_source"]["point"] }

                        }]
                }
            }
            getKeywords(es_index, query2, "subpoint").then(res => {
                const newListSubPoint = res.map(item => item.key)
                if (JSON.stringify(listSubPoint) !== JSON.stringify(newListSubPoint))
                    this.setState({
                        listSubPoint: newListSubPoint
                    })
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
        })




    }

    componentDidMount() {
        const { id, es_index } = this.props
        const { listLoc, listPoint, listSubPoint } = this.state
        const query = {
            "term": {
                "idparent": id
            }
        }
        getKeywords(es_index, query, "listloc").then(res => {

            const newListLoc = res.map(item => item.key)
            if (JSON.stringify(listLoc) !== JSON.stringify(newListLoc))

                this.setState({
                    listLoc: newListLoc
                })
        }).catch(err => {
            console.log(err)
        })

        getKeywords(es_index, query, "point").then(res => {

            const newListPoint = res.map(item => item.key)
            if (JSON.stringify(listPoint) !== JSON.stringify(newListPoint))
                this.setState({
                    listPoint: newListPoint
                })
            const query2 = {
                "bool": {
                    "must": [
                        { "term": { "idparent": id } },
                        {
                            "term": { "point": this.state.sequence["_source"]["point"] }

                        }]

                }
            }
            getKeywords(es_index, query2, "subpoint").then(res => {
                const newListSubPoint = res.map(item => item.key)
                if (JSON.stringify(listSubPoint) !== JSON.stringify(newListSubPoint))
                    this.setState({
                        listSubPoint: newListSubPoint
                    })
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
        })

        setTimeout(() => {
            this.updateText()
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }



    // onClose2() {
    //     const curSequence = this.props.playerRef.current?.playlistManager.currentVideo
    //     const { sequence, listLoc } = this.state
    //     const speaker = sequence["_source"]["listloc"]
    //     if (curSequence["_id"] === sequence["_id"] && curSequence["_source"]["listloc"].includes("Speaker ") && speaker && !speaker.includes("Speaker")) {
    //         const { es_index } = this.props
    //         const query = {
    //             "script": {
    //                 "source": "ctx._source.listloc = params.speaker",
    //                 "lang": "painless",
    //                 "params": {
    //                     "speaker": speaker
    //                 }
    //             },
    //             "query": {
    //                 "bool": {
    //                     "must": [
    //                         { "match_phrase": { "idparent": sequence["_source"]["idparent"] } },
    //                         { "match_phrase": { "listloc": curSequence["_source"]["listloc"] } }
    //                     ]
    //                 }
    //             }

    //         }
    //         const idx = listLoc.indexOf(curSequence["_source"]["listloc"]);
    //         updateByQuery(es_index, query).then(res => {
    //             if (res === 200) {

    //                 if (idx !== -1) {
    //                     listLoc[idx] = speaker;
    //                 }
    //             }
    //         }).catch(err => console.log(err))
    //     }



    //     // this.setState({
    //     //     updatedLoc: true
    //     // })

    // }


    onClose() {
        const { playerRef, es_index } = this.props;
        const curSequence = playerRef.current?.playlistManager.currentVideo;
        const { sequence, listLoc } = this.state;
        const speaker = sequence?._source?.listloc;

        if (
            curSequence?._id === sequence?._id &&
            curSequence?._source?.listloc.includes("Speaker ") &&
            speaker &&
            !speaker.includes("Speaker")
        ) {
            const query = {
                script: {
                    source: "ctx._source.listloc = params.speaker",
                    lang: "painless",
                    params: {
                        speaker: speaker,
                    },
                },
                query: {
                    bool: {
                        must: [
                            { match_phrase: { idparent: sequence?._source?.idparent } },
                            { match_phrase: { listloc: curSequence?._source?.listloc } },
                        ],
                    },
                },
            };

            const idx = listLoc.indexOf(curSequence?._source?.listloc);

            updateByQuery(es_index, query)
                .then((res) => {
                    if (res === 200 && idx !== -1) {
                        listLoc[idx] = speaker;
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    handleReset() {
        const sequence = this.state.sequence
        this.setState({
            sequence: { ...sequence, _source: { ...sequence["_source"], textAll: sequence["_source"]["texte"] } }
        })
    }

    render() {
        const { listLoc, sequence, showResume, listPoint, listSubPoint, showReformulation, reformulation, displaySubPointFilter, displayPointFilter } = this.state
        const textAll = sequence && sequence["_source"] ? sequence["_source"]["textAll"] : ""
        const texte = sequence && sequence["_source"] ? sequence["_source"]["texte"] : ""
        const synthese = sequence && sequence["_source"] ? sequence["_source"]["resumetranche"] : ""
        const speaker = sequence && sequence["_source"] ? sequence["_source"]["listloc"] : ""
        const point = sequence && sequence["_source"] ? sequence["_source"]["point"] : ""
        const subpoint = sequence && sequence["_source"] ? sequence["_source"]["subpoint"] : ""
        const idx_point = listPoint.indexOf(point)
        const num_point = point && idx_point !== -1 ? romanize(listPoint.indexOf(point) + 1) + ") " : romanize(listPoint.length + 1) + ") "
        const idx_subpoint = listSubPoint.indexOf(subpoint)
        const num_subpoint = subpoint && idx_subpoint !== -1 ? listSubPoint.indexOf(subpoint) + 1 + ") " : listSubPoint.length + 1 + ") "
        const curSequence = this.props.playerRef.current?.playlistManager.currentVideo;
        const reformulationUpdated = sequence["_source"]["reformulation"] && reformulation !== sequence["_source"]["reformulation"] ? true : false
        const textUpdated = curSequence && textAll !== curSequence["_source"]["textAll"] ? true : false
        const rowHeight = (window.innerHeight * 12) / 850
        const label_refo = this.props["type_reformulation"] ? "Reformulation (" + this.props["type_reformulation"] + ")" : "Reformulation"
        const pIsChanged = curSequence && (curSequence["_source"]["point"] !== point)
        const spIsChanged = curSequence && (curSequence["_source"]["subpoint"] !== subpoint)
        return (
            <div className='div-list'>
                <Grid container alignItems="flex-start" >
                    <Grid item xs={12} sm={8} container direction='row' spacing={2}>
                        <Grid item>
                            <Autocomplete
                                id="speaker-filter-id"
                                className='speaker-filter'
                                freeSolo
                                options={listLoc}
                                inputValue={speaker || ""}
                                onInputChange={(event, newValue) => this.handleEditLoc(newValue)}
                                renderInput={(params) => <TextField {...params} label="Locuteur" placeholder='Locuteur' />}
                            />
                        </Grid>
                        <Grid item>
                            <div className='point-annotation'>
                                <Button id="add-point-id" title="Indiquez le point de l'ordre du jour" size="small" aria-label="add point" className="add-point" onClick={this.handleAddPoint} endIcon={<MdAddCircle size={30} />} style={{ display: displayPointFilter ? "none" : "flex" }}>
                                    Indiquez le point de l'ordre du jour
                                </Button>

                                <Box id="point-filter-id" className='point-filter' style={{ display: displayPointFilter ? "flex" : "none" }}>
                                    <Tooltip title={point || ""} placement="top" >
                                        <Autocomplete
                                            id="point-input-id"
                                            className={pIsChanged ? "point point-changed" : "point"}
                                            freeSolo
                                            options={listPoint}
                                            inputValue={point || ""}
                                            onInputChange={(event, newValue, reason) => this.handleEditPoint(newValue, reason)}
                                            onReset={() => {
                                                console.log("reset ....")
                                            }}
                                            renderInput={(params) => <TextField  {...params} label="Modifiez ou supprimez le Point" InputProps={{
                                                ...params.InputProps, startAdornment: !pIsChanged && <InputAdornment position="start" >{num_point}</InputAdornment>,
                                            }} placeholder='Point' />}
                                        />
                                    </Tooltip>
                                    <Button id="add-subpoint-id" className="add-subpoint" title="Indiquez un sous-point ou sous-partie" size="small" aria-label="add" onClick={this.handleAddSubPoint} endIcon={<MdAddCircle size={30} />} style={{ display: displaySubPointFilter ? "none" : "block" }}>
                                        Indiquez un sous-point ou sous-partie
                                    </Button>
                                    <Box id="subpoint-filter-id" className='subpoint-filter' style={{ display: displaySubPointFilter ? "flex" : "none" }}>

                                        <Tooltip title={subpoint || ""} placement="top" >
                                            <Autocomplete
                                                id="subpoint-input-id"
                                                className={spIsChanged ? "point point-changed" : "point"}
                                                freeSolo
                                                options={listSubPoint}
                                                inputValue={subpoint || ""}
                                                onInputChange={(event, newValue, reason) => this.handleEditSubPoint(newValue, reason)}
                                                onReset={() => {
                                                    console.log("reset ....")
                                                }}

                                                renderInput={(params) => <TextField  {...params} label="Modifiez ou supprimez le sous-point" placeholder='Sous-point' InputProps={{
                                                    ...params.InputProps, startAdornment: !spIsChanged && <InputAdornment position="start" >{num_subpoint}</InputAdornment>,
                                                }} />}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>

                            </div>
                        </Grid>
                        {/* <TextField
                            id="standard-text-loc"
                            className='text'
                            label="Locuteur"
                            value={listloc}
                            onChange={this.handleEditLoc}
                            style={{ width: 200 }}
                        > 
                        </TextField>*/}

                    </Grid>
                    <Grid item xs container direction='row' justifyContent="flex-end" alignItems="flex-start" >
                        {synthese && <Grid item xs>

                            <FormControlLabel
                                control={<Switch color="primary" checked={showResume} />}
                                label="Synthèse"
                                labelPlacement="top"
                                className='reformulation-switch'
                                onChange={this.handleShowResume}
                            />

                        </Grid>}
                        {reformulation && <Grid item xs>

                            <FormControlLabel
                                control={<Switch color="primary" checked={showReformulation} />}
                                label="Reformulation"
                                labelPlacement="top"
                                className='reformulation-switch'
                                onChange={this.handleShowReformulation}
                            />

                        </Grid>}
                        <Grid item xs container direction='column' spacing={1}>
                            <Grid item xs>

                                <Tooltip title="Supprimer l'extrait" placement="top" >
                                    <IconButton className='bt-delete' onClick={this.handleOpenDialog}>
                                        <MdDeleteOutline />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {textAll !== texte && <Grid item xs>
                                <Tooltip title="Réinitialiser la transcription de l'extrait" placement="top" >
                                    <IconButton className='bt-update' onClick={this.handleReset}>
                                        <RxReset />
                                    </IconButton>
                                </Tooltip>
                            </Grid>}
                        </Grid>

                    </Grid>

                </Grid>
                <div >

                    <Grid container alignItems="flex-end">

                        <Grid item xs={10} container direction='column' spacing={1}>

                            {/* <TextField
                                id="standard-multiline-text"
                                className='text'
                                label="Texte"
                                multiline
                                maxRows={8}
                                value={textAll}
                                onChange={this.handleEditText}
                            >
                            </TextField> */}

                            <Grid item xs>
                                <span className='text-label'> Transcription : </span>
                                <TextareaAutosize
                                    id="standard-multiline-text"
                                    className={textUpdated ? "transcription text-updated" : 'transcription'}
                                    maxRows={rowHeight}
                                    defaultValue={textAll}
                                    value={textAll}
                                    onChange={(e) => this.handleEditText(e, "textAll")}
                                    placeholder='texte'

                                />
                            </Grid>
                            {reformulation && showReformulation && <Grid item xs>
                                <span className='text-label-re'>{label_refo}</span>
                                <TextareaAutosize
                                    id="standard-multiline-formulation"
                                    className={reformulationUpdated ? "reformulation text-updated" : "reformulation"}
                                    maxRows={rowHeight}
                                    defaultValue={reformulation}
                                    value={reformulation}
                                    onChange={(e) => this.handleEditText(e, "reformulation")}
                                    placeholder='Reformulation'
                                />
                            </Grid>}
                            {showResume && <Grid item xs>
                                <span className='text-label'>Synthèse :</span>
                                <TextareaAutosize
                                    id="standard-multiline-resume"
                                    className='text'
                                    maxRows={rowHeight}
                                    defaultValue={synthese}
                                    value={synthese}
                                    onChange={(e) => this.handleEditText(e, "resumetranche")}
                                    placeholder='synthèse'
                                />
                            </Grid>}

                            {/* <HighlightWithinTextarea
                            id="standard-multiline-text"
                            className='text'
                            highlight={searchValue}
                            value={textAll}
                            onChange={(value) => this.handleEditText(value)}
                        /> */}
                        </Grid>
                        <Grid item xs>
                            <Tooltip title="Validez les modifications et passez à l'extrait suivant" placement="top" >

                                <IconButton className='bt-update' onClick={this.handleUpdateIndex}>
                                    <MdOutlineSave />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </div>


                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="dialog-delete"
                >
                    <DialogTitle id="dialog-delete">
                        Confirmation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Souhaitez-vous vraiment supprimer cette séquence ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDialog}
                            className="button plain primaryMain"
                        >
                            Annuler
                        </Button>
                        <Button
                            onClick={this.handleDelete}
                            className="button plain bg-danger"
                        >
                            Supprimer
                        </Button>

                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={this.state.openMessage["open"]}
                    autoHideDuration={3000}
                    onClose={this.handleCloseMessage}
                >
                    <Alert
                        onClose={this.handleCloseMessage}
                        severity="success"
                    >
                        {this.state.openMessage["message"]}
                    </Alert>
                </Snackbar>
            </div >
        );
    }
}

export default AnnotationComponent;