import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Profile from './components/admin/Profile';
import Documentation from './components/Documentation';
import LostPassWord from './components/admin/LostPassWord';
import ResetMDP from './components/admin/ResetMDP';
import NavBar from './components/NavBar';
import "./styles/App.css";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Dialog, DialogContent, StylesProvider } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';
import Upload from './components/upload/Upload';
import { getUserToken } from './utils/functions';
import Login from './components/admin/Login';
import MySpace from './components/dashboard/MySpace';
import Annotation from './components/dashboard/annotation/Annotation';
import EditResume from './components/dashboard/EditResume';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976D2' },
    },
  },
  frFR
);

class App extends Component {


  render() {
    const open = !Boolean(getUserToken())
    return (
      <BrowserRouter basename='/'>
        <ThemeProvider theme={theme}>
          <div className="App">
            <StylesProvider injectFirst>
              <div className="mainContainer">
                {!open ?
                  <Routes>
                    <Route exact path="/" element={<NavBar mainComponent={MySpace} />} />
                    <Route exact path="/accueil" element={<NavBar mainComponent={Documentation} />} />
                    <Route exact path="/connexion" element={<NavBar mainComponent={Login} />} />
                    <Route exact path="/profil" element={<NavBar mainComponent={Profile} />} />
                    <Route exact path="/documentation" element={<NavBar mainComponent={Documentation} />} />
                    <Route exact path="/myspace" element={<NavBar mainComponent={MySpace} />} />
                    <Route exact path="/lostPassWord" element={<NavBar mainComponent={LostPassWord} />} />
                    <Route exact path="/reinitialiserMDP" element={<NavBar mainComponent={ResetMDP} />} />
                    <Route exact path="/upload" element={<NavBar mainComponent={Upload} />} />
                    <Route exact path="/edit" element={<NavBar mainComponent={Annotation} />} />
                    <Route exact path="/synthesis" element={<NavBar mainComponent={EditResume} />} />

                  </Routes>
                  :
                  <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    ref={"login-dialog"}
                    maxWidth={"xs"}
                  >
                    <DialogContent className='dialog-login'>
                      <Login />
                    </DialogContent>

                  </Dialog>
                }
              </div>

            </StylesProvider>
          </div>
        </ThemeProvider >

      </BrowserRouter >
    );
  }
}

export default App;