import React from 'react';
import clsx from 'clsx';

import { Link as RouterLink } from 'react-router-dom';
import LogRegister from './admin/Login';
import { AppBar, CssBaseline, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, } from '@material-ui/core';
import { getParamConfig, getUserToken } from '../utils/functions';
import { MdMenuBook, MdLogout, MdOutlineFolderOpen } from 'react-icons/md';

import { RiVideoUploadLine } from 'react-icons/ri';
import "../styles/NavBarBis.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, DialogTitle, Link, Tooltip } from '@material-ui/core';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#42414d"

    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,

    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        backgroundColor: "#42414d"

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,


    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        backgroundColor: "#000000"


    },
    active: {
        textDecoration: 'none',
        backgroundColor: '#42414d',
        color: "#fbfbfe"
    },
    no_active: {
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
        },
        color: "#424242"
    },
    header: {
        display: "inline-block",
        width: '100%',
        fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
        maxHeight: '40px',
    },
    homeBt: {
        textDecoration: 'none',
        cursor: 'pointer',
        padding: '6px 12px',
        textTransform: 'none',
        backgroundColor: '#eceff1',
        border: '1px solid',
        borderRadius: '5px',

        boxShadow: 'none',
        color: '#263238',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    userSpace: {
        margin: '0',
        textDecoration: 'none',
        cursor: 'pointer',
        // padding: '6px 12px',
        textTransform: 'none',
        // border: '1px solid',
        // borderRadius: '5px',
        float: 'right',
        boxShadow: 'none',
        color: '#D8DBE8',
        '&:hover': {
            textDecoration: 'none',
            color: '#A5ACCA',
        },
    },
    closeButton: {
        position: 'absolute!important',
        right: '5px!important',
    },
    title: {
        display: "inline-block",
        fontSize: '2em',
        textAlign: "center",
        margin: 'auto',
        width: '90%',
        fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
    },
    icon: {
        display: "block",
        marginRight: theme.spacing(0.5),
        width: 30,
        height: 30,
    },

}));

function ListItemLink(props) {
    return <ListItem component={Link} {...props} />;
}

export default function NavBar(props) {
    const classes = useStyles();
    const [selectedId, setSelectedId] = React.useState('myspace');
    const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
    const usertoken = getUserToken();

    const handleClose = () => {
        setOpenLoginDialog(false);
    };




    const handleListItemClick = (e) => {
        setSelectedId(e.currentTarget.name);
    };

    const handleLogoutClick = () => {
        localStorage.clear();
        document.location.href = getParamConfig('web_url');
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>

                    <div className={classes.header}>


                        <Typography variant="h6" className={"title"}>
                            AUDIO/VIDEO TRANSCRIPTION & EDITION
                        </Typography>

                        {usertoken && <Link
                            id="userSpace"
                            className={classes.userSpace}
                            onClick={handleLogoutClick}

                        >
                            <MdLogout className={classes.icon} />
                            Se déconnecter
                        </Link>
                        }

                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, classes.drawerClose)}
                classes={{
                    paper: clsx(classes.drawerClose),
                }}
            >
                <div className={classes.toolbar}>

                </div>
                <Divider />
                <List className={classes.list}>
                    <ListItemLink
                        key={'myspace'}
                        onClick={handleListItemClick}
                        name="myspace"
                        className={
                            selectedId === 'myspace'
                                ? classes.active
                                : classes.no_active
                        }
                        component={RouterLink}
                        to="/myspace"
                    >
                        <Tooltip title={'Espace utilisateur'} arrow>
                            <ListItemIcon>
                                <MdOutlineFolderOpen size={30} color={selectedId === 'myspace' ? "#fff" : "#9E9E9E"} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Mon espace'} />
                    </ListItemLink>


                    <Tooltip
                        title={
                            Boolean(usertoken)
                                ? ''
                                : 'Connectez-vous pour indexer vos vidéos !'
                        }
                        arrow
                    >
                        <span>
                            <ListItemLink
                                key={'upload'}
                                onClick={handleListItemClick}
                                name="upload"
                                className={
                                    selectedId === 'upload'
                                        ? classes.active
                                        : classes.no_active
                                }
                                component={RouterLink}
                                to="/upload"
                                disabled={!Boolean(usertoken)}
                            >
                                <ListItemIcon>
                                    <RiVideoUploadLine size={30} color={selectedId === 'upload' ? "#fff" : "#9E9E9E"} />
                                </ListItemIcon>

                                <ListItemText primary={'Upload'} />
                            </ListItemLink>
                        </span>
                    </Tooltip>


                    <ListItemLink
                        key={'documentation'}
                        onClick={handleListItemClick}
                        name="documentation"
                        className={
                            selectedId === 'documentation'
                                ? classes.active
                                : classes.no_active
                        }
                        component={RouterLink}
                        to="/documentation"
                    >
                        <Tooltip title={'Documentation utilisateur'} arrow>
                            <ListItemIcon>
                                <MdMenuBook size={30} color={selectedId === 'documentation' ? "#fff" : "#9E9E9E"} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Documentation utilisateur'} />
                    </ListItemLink>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <props.mainComponent />
            </main>
            <Dialog
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={usertoken ? false : openLoginDialog}
                onClose={handleClose}
                className='dialog-login'
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    <Button
                        aria-label="close"
                        className="closeButton"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </Button>
                </DialogTitle>

                <DialogContent>
                    <LogRegister />
                </DialogContent>
            </Dialog>
        </div>
    );
}