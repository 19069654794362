import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  styled,
  Button,
  LinearProgress
} from '@mui/material';
import { Delete, DragIndicator } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { getParamConfig } from '../../../utils/functions';
import axios from 'axios';
import { SHA1 } from 'crypto-js';

const StyledBt = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1f8299",
  color: "#fff",
  fontSize: "1rem",
  marginTop: "1em",
  "&:hover": {
    backgroundColor: "#8db2c2",
  }
})

const AudioFileList = ({ files, onDelete, onReorder, handleNext }) => {
  const [uploadProgress, setUploadProgress] = React.useState({});
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    onReorder(sourceIndex, destinationIndex);
  };

  const handleUpload = async () => {
    const uploadedFiles_ = []
    for (const file of files) {
      const formData = new FormData();
      formData.append('video', file.file, file.newName);

      // Réinitialise la progression pour ce fichier
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.id]: 0,
      }));

      try {
        const api = getParamConfig("api");
        const res = await axios.post(api + '/util/upload', formData, {
          headers: { "Access-Control-Allow-Origin": "*" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.id]: percentCompleted,
            }));

          },
        });
        if ([200, 204].includes(res.status)) {
          setUploadedFiles((prevUploaded) => [...prevUploaded, file.id]);
          uploadedFiles_.push(file.id)
        } else {
          console.error("Erreur lors du téléchargement :", res);
        }
      } catch (err) {
        console.error("Erreur de téléchargement :", err);
      }
    }

    if (uploadedFiles_.length === files.length) {
      handleNext({
        files: files.map(file => file.newName), title: files[0].name, id: SHA1(files[0].name + "_" + files.length).toString()
      });
    }

  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="audioFiles">
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ width: '100%' }}
          >
            {files.length === 0 ? (
              <Typography variant="body1" color="textSecondary" align="center">
                Aucun fichier audio chargé
              </Typography>
            ) : (
              files.map((file, index) => (
                <Draggable key={file.id} draggableId={file.id} index={index}>
                  {(provided, snapshot) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      elevation={snapshot.isDragging ? 3 : 1}
                      sx={{
                        mb: 1,
                        backgroundColor: uploadedFiles.includes(file.id)
                          ? '#e0ffe0' // Light green background for uploaded files
                          : snapshot.isDragging
                            ? '#f5f5f5'
                            : 'white',
                      }}
                    >
                      <ListItem>
                        <div {...provided.dragHandleProps} style={{ marginRight: 16 }}>
                          <DragIndicator color="action" />
                        </div>
                        <ListItemText
                          primary={file.name}
                          secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                        />
                        {uploadProgress[file.id] !== undefined && uploadProgress[file.id] < 100 && (
                          <div style={{ width: '100%', marginTop: '8px' }}>
                            <LinearProgress
                              variant="determinate"
                              value={uploadProgress[file.id]}
                            />
                          </div>
                        )}
                        <ListItemSecondaryAction>
                          {!uploadedFiles.includes(file.id) && <IconButton edge="end" onClick={() => onDelete(file.id)}>
                            <Delete />
                          </IconButton>}
                        </ListItemSecondaryAction>
                      </ListItem>

                    </Paper>
                  )}

                </Draggable>
              )
              )
            )}
            {provided.placeholder}
          </List>
        )}

      </Droppable>
      <StyledBt
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={files.length === 0}
      >
        Suivant
      </StyledBt>
    </DragDropContext>
  );
};

export default AudioFileList;