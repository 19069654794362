
import React, { Component } from "react";
import { MdClose, MdOutlineFileUpload } from "react-icons/md";
import {
  getHitsFromQuery,
  getParamConfig,
  getUserToken,
  isValidSHA1,
  upload,
} from "../../utils/functions";
import "../../styles/AddInfo.css";
import sha1 from 'crypto-js/sha1';
import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Input, InputAdornment, Link, MenuItem, Radio, RadioGroup, styled, TextField } from "@mui/material";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from 'date-fns/locale/fr';

registerLocale('fr', fr)


const StyledLink = styled(Link)({
  color: " #fff",
  backgroundColor: "#1f8299",
  fontSize: "1.2rem",
  border: 0,
  borderRadius: 3,
  padding: `10px 2em`,
  textTransform: "none",
  textDecoration: "none",
  margin: `4em 4em`,
  cursor: "pointer",
  "&:hover": {
    textTransform: "none",
    textDecoration: "none",
    backgroundColor: "#8db2c2",
  }
});


class AddInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Composed TextField",
      fileName: "",
      file: null,
      data: { ...this.props.data, langue: "fr", time: 120, reformulation: "", reu_date: new Date(), },
      langue: "fr",
      appconfig: [],
      fileNote: null,

    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleReformulationChange = this.handleLanguageChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleChange = (name, event) => {
    let newData = this.state.data;
    if (name === "reformulation")
      newData["reformulation"] = newData["reformulation"] === event.target.value ? "" : event.target.value;
    else
      newData[name] = event.target.value;
    this.setState({
      data: newData,
    });
  };

  handleDateChange = (date) => {
    console.log("date", date);
    this.setState({
      data: { ...date, reu_date: date },
    });
  };

  updateFileDisplay = (e) => {
    let input = document.getElementById("file_uploads");
    var curFiles = input.files;
    if (curFiles.length === 1) {
      const data = this.state.data;
      let newChecked = [...data["checked"]];
      let currentIndex = newChecked.indexOf(0);
      if (currentIndex !== -1) newChecked.splice(currentIndex, 1);
      currentIndex = newChecked.indexOf(1);
      if (currentIndex !== -1) newChecked.splice(currentIndex, 1);

      this.setState({
        file: curFiles[0],
        fileName: curFiles[0].name,
        data: { ...this.state.data, checked: newChecked },
      });
    }
  };

  updateNoteDisplay = (e) => {
    let input = document.getElementById("note_uploads");
    var curFiles = input.files;
    if (curFiles.length === 1) {

      this.setState({
        fileNote: curFiles[0],
      });
    }
  };


  handleToggle = (value) => {
    const data = this.state.data;
    const currentIndex = data["checked"].indexOf(value);
    const newChecked = [...data["checked"]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      data: { ...this.state.data, checked: newChecked },
    });
  };

  handleLanguageChange = (event) => {
    this.setState({
      langue: event.target.value,
      data: { ...this.state.data, langue: event.target.value },
    });
  };

  componentDidMount() {
    let input = document.getElementById("file_uploads");
    if (input !== null) {
      input.addEventListener("change", this.updateFileDisplay);
      input.style.opacity = 0;
    }

    let inputNote = document.getElementById("note_uploads");
    if (inputNote !== null) {
      inputNote.addEventListener("change", this.updateNoteDisplay);
      inputNote.style.opacity = 0;
    }

    const checked =
      "checked" in this.state.data ? this.state.data["checked"] : [0, 1, 2, 3];

    this.setState({
      data: {
        ...this.state.data,
        checked: checked
      },
    });
    const paramConfig = getParamConfig();
    const userToken = getUserToken()
    getHitsFromQuery(
      paramConfig["es_host"],
      "appconfig",
      paramConfig["es_credential"],
      { size: 500, query: { match_all: {} } }
    )
      .then((res) => {
        let appconfig = []
        const indexDemo = userToken && userToken["indexDemo"] ? Array.isArray(userToken["indexDemo"]) ? userToken["indexDemo"] : [userToken["indexDemo"]] : null
        if (indexDemo) {

          for (const item of res) {

            if (indexDemo.includes(item._source["name"]))
              appconfig.push(item._source)

          }
        } else {
          appconfig = res.map((item) => item._source);
        }

        this.setState({
          appconfig: appconfig,
          data: { ...this.state.data },

        });

      })
      .catch((e) => {
        console.log("error :", e);
      });




  }

  handleUpload = async () => {
    const { file, data, fileNote } = this.state;
    if (!data["indice"]) {
      this.setState({
        data: { ...this.state.data, indice: "" },
      });
    } else {
      let isUpload = true;
      this.setState({
        loading: true,
      });
      if (file) {
        const formData = new FormData();
        const parts = file.name.split(".");
        const ext = parts.pop();
        const fileName = parts.join(".");
        const newName = isValidSHA1(fileName) ? file.name : sha1(fileName) + "." + ext;
        formData.append("video", file, newName);

        const res = await upload(formData);
        if ([200, 204].includes(res.status)) {
          data["srtFile"] = newName;

        } else {
          isUpload = false;
          console.log("error upload !", JSON.stringify(res));

        }
        // upload(formData)
        //   .then((res) => {
        //     this.setState({ loading: false });
        //     console.log(res.status);
        //     if ([200, 204].includes(res.status)) {
        //       data["srtFile"] = newName;

        //       this.props.handleNext(data);
        //     } else {
        //       console.log("error upload !", JSON.stringify(res));
        //     }
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //   });
      }
      if (fileNote) {
        const formData = new FormData();
        const [fileName, ext] = fileNote.name.split(".")
        const newName = isValidSHA1(fileName) ? file.name : sha1(fileName) + "." + ext;
        formData.append("video", fileNote, newName);

        const res = await upload(formData);
        if ([200, 204].includes(res.status)) {
          data["noteFile"] = newName;

        } else {
          isUpload = false;
          console.log("error upload !", JSON.stringify(res));
        }

      }
      if (isUpload) {
        this.setState({
          loading: false,
        });
        this.props.handleNext(data);
        console.log("success upload !", JSON.stringify(data));
      }
      else {
        console.log("error upload !");
      }
    }
  }

  render() {
    const { data, appconfig, fileNote } = this.state;
    const clients = appconfig.map((indice) => indice["displayName"]);
    const userToken = getUserToken()
    const freeSolo = userToken && userToken["indexDemo"] ? Array.isArray(userToken["indexDemo"]) && userToken["indexDemo"].length > 0 ? false : true : true
    return (
      <div className="add-info">
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              id="title"
              variant="filled"
              label="Titre de votre réunion ou manifestation"
              value={data["title"]}
              onChange={(e) => this.handleChange("title", e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <span className="date-label">Date de votre réunion ou manifestation</span>
            <DatePicker title="date" className="date-picker" selected={data["reu_date"]} onChange={(date) => this.handleDateChange(date)} locale="fr" dateFormat="dd/MM/yyyy"
            />
          </Grid>
          <Grid item md={4} xs={12}>
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              id="autocomplete-index"
              className="list-indices"
              freeSolo={freeSolo}
              options={clients}
              getOptionLabel={(option) => option}
              onInputChange={(event, newValue) => {
                let newData = data;
                const config = appconfig.find((app) => app.displayName === newValue)
                newData["indice"] = newValue
                newData["videoIndex"] = config && config["overviewIndex"] ? config["overviewIndex"] : "video-" + newValue
                newData["retinIndex"] = config && config["index"] ? config["index"] : "retin-" + newValue
                this.setState({
                  data: newData,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choisissez l'instance ou le type de réunion"
                  variant="filled"
                  error={data["indice"] === ""}
                />
              )}
            />
          </Grid>
          {/*
          <Grid item md={8} xs={12}>
            <FormControl className={""} variant="outlined">
              <Input
                id="outlined-adornment-password"
                className="input-srt text-field"
                type={"text"}
                value={fileName}
                placeholder="Télecharger le fichier .srt ici"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({ fileName: "", file: null })
                      }
                    >
                      <MdClose />
                    </IconButton>
                    <label htmlFor="file_uploads">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        component="span"
                        onClick={this.updateFileDisplay}
                      >
                        <MdOutlineFileUpload />
                      </IconButton>
                    </label>
                  </InputAdornment>
                }
              />
              <input
                type="file"
                id="file_uploads"
                name="myFiles"
                accept=".srt, .vtt"
              />
            </FormControl>
          </Grid>
          */}
          {/* <Grid item md={4} xs={12}>
            <TextField
              id="header"
              className="text-field"
              label="En-tête"
              defaultValue={this.state.data["header"]}
              onChange={(e) => this.handleChange("title", e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="footer"
              className="text-field"
              label="pied de page"
              defaultValue={this.state.data["footer"]}
              onChange={(e) => this.handleChange("description", e)}
            />
          </Grid> */}
          <Grid item md={4} xs={12}>
            <TextField
              id="select"
              variant="filled"
              label="Langue"
              value={this.state.langue}
              select
              onChange={this.handleLanguageChange}
            >
              <MenuItem value="auto">Auto</MenuItem>
              <MenuItem value="fr">Français</MenuItem>
              <MenuItem value="en">Anglais</MenuItem>
            </TextField>

          </Grid>
          <Grid item md={4} xs={12}>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl className={""} variant="outlined">
              <Input
                id="outlined-adornment-note"
                className="input-note"
                type={"text"}
                value={fileNote ? fileNote.name : ""}
                placeholder="Télechargez la prise de note ici"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({ fileNote: null })
                      }
                    >
                      <MdClose color="#b8b8bb" />
                    </IconButton>
                    <label htmlFor="note_uploads">
                      <IconButton
                        aria-label="toggle note visibility"
                        edge="end"
                        component="span"
                        onClick={this.updateNoteDisplay}
                      >
                        <MdOutlineFileUpload color="#b8b8bb" />
                      </IconButton>
                    </label>
                  </InputAdornment>
                }
              />
              <input
                type="file"
                id="note_uploads"
                name="myFiles"
                accept=".pdf, .docx, .doc"
              />
            </FormControl>

          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl>
              <FormLabel sx={{ color: "#b8b8bb" }}>Option de reformulation : choisissez le format souhaité de votre document</FormLabel>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={this.state.data["reformulation"]}
                onClick={(e) => this.handleChange("reformulation", e)}
                row
              >
                <FormControlLabel value="8" control={<Radio />} label="Transcription reformulée" />
                <FormControlLabel value="9" control={<Radio />} label="Compte rendu reformulé" />
                <FormControlLabel value="10" control={<Radio />} label="Synthèse" />
              </RadioGroup>
            </FormControl>

            {/* <TextField
              id="select"
              className="text-field"
              variant="filled"
              label="Reformulation"
              value={this.state.data["reformulation"]}
              select
              onChange={(e) => this.handleChange("reformulation", e)}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="8">95%</MenuItem>
              <MenuItem value="9">80%</MenuItem>
              <MenuItem value="10">50%</MenuItem>
            </TextField> */}

          </Grid>
        </Grid>
        <div className={"stepContent"}>
          <div>
            <Button
              disabled={this.state.activeStep === 0}
              onClick={this.props.handleBack}
              className={"previewBt"}
            >
              Précédent
            </Button>


            <StyledLink
              key={"end"}
              onClick={(e) => this.handleUpload()}
              name="end"
              component={Link}
              to={"/myspace"}
              style={{ pointerEvents: data["indice"] ? "auto" : "none", backgroundColor: data["indice"] ? "#1f8299" : "#b8b8bb" }}
            >
              Valider
            </StyledLink>


          </div>

        </div>
      </div>
    );
  }
}

export default AddInfo;
