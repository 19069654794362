import React, { Component } from "react";

import { MdLockPerson, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { checkIfLogin, login, setWithExpiry } from "../../utils/functions";
import "../../styles/Login.css"
import { Alert, Avatar, Box, Button, Container, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material";

const BtLogin = styled(Button)({
    textTransform: "none",
    backgroundColor: "#1f8299",
    color: "#fff",
    fontSize: "1.2rem",
    padding: "10px 20px",
    "&:hover": {
        backgroundColor: "#8db2c2",
    }
});

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            error: "",
            showPassword: false,
            mailError: false,
            passError: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            mailError: false,
            passError: false,
        });
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    onSubmit(e) {
        e.preventDefault();
        if (this.state.email && this.state.password) {
            const userusername = this.state.email.split('@')[0];
            const user = {
                userName: userusername,
                password: this.state.password,
            };
            login(user).then((res) => {
                if (res.status === 200) {
                    setWithExpiry("usertoken", res["res"], 35940000);
                    setInterval(checkIfLogin, 900000);
                    document.location.reload(true);
                } else {
                    const err = res.error
                        ? res.error
                        : "La connexion au serveur a échoué !";

                    this.setState({
                        error: err,
                    });
                }
            });
        } else if (!this.state.email && !this.state.password) {
            this.setState({
                error: "Saisissez l'adresse e-mail et le mot de passe !",
                mailError: true,
                passError: true,
            });
        } else if (!this.state.email) {
            this.setState({
                error: "Saisissez l'adresse e-mail !",
                mailError: true,
                passError: false,
            });
        } else {
            this.setState({
                error: "Saisissez  le mot de passe !",
                mailError: false,
                passError: true,
            });
        }
    }

    render() {
        return (

            <Container component="main" maxWidth="xs" className="login">
                <Box
                    className="box"
                >
                    <Avatar className="icon">
                        <MdLockPerson />
                    </Avatar>
                    <Typography className="text">
                        Connexion
                    </Typography>
                    {this.state.error !== "" ? (
                        <Alert severity="error">{this.state.error}</Alert>
                    ) : (
                        ""
                    )}
                    <Box
                        id="loginForm"
                        className="form"
                        component="form"
                        onSubmit={this.onSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            id="email"
                            variant="outlined"
                            className="input"
                            required

                            label="Identifiant"
                            type="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={this.state.email}
                            onChange={this.onChange}
                            error={this.state.mailError}
                        />
                        <TextField
                            id="password"
                            variant="outlined"
                            className="input"
                            required
                            label="Mots de passe"
                            type={this.state.showPassword ? "text" : "password"}
                            name="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.onChange}
                            error={this.state.passError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className="togglePassword"
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? (
                                                <MdVisibility color="#858585" />
                                            ) : (
                                                <MdVisibilityOff color="#858585" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box pt={2} display="flex" justifyContent="flex-end">
                            <BtLogin
                                variant="contained"
                                type="submit"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Se connecter
                            </BtLogin>
                        </Box>
                    </Box>
                </Box>
            </Container>
        );
    }
}

export default Login;
