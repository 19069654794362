import React, { useRef } from 'react';
import { Button, Box } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { isValidSHA1 } from '../../../utils/functions';
import { SHA1 } from 'crypto-js';

const FileUploader = ({ onFilesSelected }) => {
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const audioFiles = files.filter(file => file.type.startsWith('audio/'));

    if (audioFiles.length > 0) {

      const filesWithIds = audioFiles.map(file => {
        const parts = file.name.split(".");
        const ext = parts.pop();
        const fileName = parts.join(".");
        const newName = isValidSHA1(fileName) ? file.name : SHA1(fileName)
        return ({
          id: Math.random().toString(36).substr(2, 9),
          newName: newName + "." + ext,
          name: fileName,
          size: file.size,
          file: file
        })
      });
      onFilesSelected(filesWithIds);
    }

    // Reset input
    event.target.value = '';
  };

  return (
    <Box sx={{ textAlign: 'center', mb: 3 }}>
      <input
        type="file"
        multiple
        accept="audio/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={() => fileInputRef.current.click()}
      >
        Chargez des fichiers audio / video
      </Button>
    </Box>
  );
};

export default FileUploader;