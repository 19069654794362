import React from 'react';

import { MdInfo } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import AddInfo from './AddInfo';
import "../../styles/Upload.css";
import { getUserToken, index, setWithExpiry } from '../../utils/functions';
import { Box, IconButton, Snackbar, SnackbarContent, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import AudioUpload from './AudioUpload/AudioUpload';

class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            data: {},
            open: false,
            openAlert: false,
            mess: '',

        };
        this.handleBack = this.handleBack.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleNotificationClose =
            this.handleNotificationClose.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleNext(newValue) {
        let newData = this.state.data;
        for (let key of Object.keys(newValue)) {
            newData[key] = newValue[key];
        }

        if (this.state.activeStep === 1) {
            const userToken = getUserToken()
            newData["user"] = userToken["user_name"]
            newData["email"] = userToken["user_email"]
            newData["title"] = newData["title"].replaceAll(" ", "_")
            setWithExpiry("indice", newData["indice"], 35940000)
            index(newData).then(res => {
                document.location.href = "/";

                console.log(res)

            }).catch(err => { console.log(err) });

        } else {
            this.setState({
                activeStep: this.state.activeStep + 1,
                data: newData
            });
        }
        this.setState({
            activeStep: this.state.activeStep + 1,
            data: newData
        });
    }
    handleBack() {
        this.setState({
            activeStep: this.state.activeStep - 1,
            open: false,
        });
    }
    handleEnd(res) {
        this.setState({
            activeStep: this.state.activeStep + 1,
            open: false,
            openAlert: true,
            mess:
                res.statusCode === 200
                    ? 'La modification de vos données a bien été prise en compte.'
                    : 'Erreur : la modification de vos données a échoué.',
        });
    }
    handleNotificationClose(event) {
        this.setState({
            anchorElSearch: null,
            openAlert: false,
        });
    }

    handleCloseDialog() {
        this.setState({
            open: false,
        });
    }

    render() {
        return (
            <div className="upload">
                <Stepper
                    activeStep={this.state.activeStep}
                    orientation="vertical"
                    className='stepper'
                >
                    <Step key={0}>
                        <StepLabel>{"Télécharger le fichier"}</StepLabel>
                        <StepContent>
                            <AudioUpload handleNext={this.handleNext} />

                        </StepContent>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Saisir les informations</StepLabel>
                        <StepContent>
                            <AddInfo handleNext={this.handleNext} handleBack={this.handleBack} data={this.state.data} />

                        </StepContent>
                    </Step>

                </Stepper>

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    key="topCenter"
                    open={this.state.openAlert}
                    onClose={this.handleNotificationClose}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={
                            <Box
                                display="flex"
                                alignItems="center"
                                id="client-snackbar"
                            >
                                <MdInfo className="icon" />
                                <div>{this.state.mess}</div>
                            </Box>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleNotificationClose}
                            >
                                <IoMdCloseCircle className="icon" />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default Upload;