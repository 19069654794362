import React from "react";
import {
  ReactiveBase,
  ReactiveList,
  DataSearch,
  ReactiveComponent,
  MultiDropdownList,
  SelectedFilters,
  MultiDropdownRange,
} from "@appbaseio/reactivesearch";
// import styled from "styled-components";
import "../../../../styles/SearchComponent.css";
import { MdClose, MdHelp } from "react-icons/md";
import { getKeywords } from "../../../../utils/functions";
import { Box, Fade, Grid, IconButton, Popper } from "@mui/material";

export default class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      from: this.props.from,
      idparent: this.props.id,
      key: 0,
      anchorEl: null,
      listPoint: [],
      listSubPoint: []
    };
    this.queryBuilderFunc = this.queryBuilderFunc.bind(this);
    this.handleHelpClose = this.handleHelpClose.bind(this);
    this.handleHelpOpen = this.handleHelpOpen.bind(this);
    // this.handleSelectedFiltersChange = this.handleSelectedFiltersChange.bind(this);
  }

  // handleSelectedFiltersChange(newFilter) {
  //   const filters = ["speaker", "textSearch", "rangeDuree", "point"]
  //   for (const filter of filters) {
  //     if (newFilter[filter] && newFilter[filter]["value"] && newFilter[filter]["value"].length > 0 && this.state.from > 0) {
  //       this.setState({
  //         from: this.props.from
  //       })
  //     }
  //   }
  // }

  handleHelpClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleHelpOpen(event) {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
      anchorElSearch: null,
    });
  }

  queryBuilderFunc(value, props) {
    if (value) {
      let terms = value.split("/");
      if (terms.length > 1) {
        terms[0] = terms[0].trim().replace(/ /g, " + ");
        value = terms[0] + "+(" + terms[1] + ")|" + terms[0];
      }

      return {
        query: {
          simple_query_string: {
            query: value,
            fields: props.dataField,
            analyze_wildcard: true,
          },
        },
      };
    } else {
      return {
        query: {
          match_all: {}
        },
      };
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.from !== prevProps.from) {
      // Update state based on the new props
      this.setState({
        from: this.props.from,
      });
    }
    if (this.props.id !== prevProps.id) {
      this.setState({
        idparent: this.props.id,

      });
    }
    if (this.props.refreshKey !== prevProps.refreshKey) {
      const query = {
        "term": {
          "idparent": this.props.id
        }
      }
      const es_index = this.props.es_info["index"]
      getKeywords(es_index, query, "point").then(res => {
        const newListPoint = res.map(item => item.key)
        if (JSON.stringify(this.state.listPoint) !== JSON.stringify(newListPoint))
          this.setState({
            listPoint: newListPoint,
            key: this.state.key + 1
          })
        else
          this.setState({ key: this.state.key + 1 })
      }).catch(err => {
        console.log(err)
        this.setState({ key: this.state.key + 1 })

      })

      const query2 = {
        "term": {
          "idparent": this.props.id
        }
      }
      getKeywords(es_index, query2, "subpoint").then(res => {
        const newListSubPoint = res.map(item => item.key)
        if (JSON.stringify(this.state.listSubPoint) !== JSON.stringify(newListSubPoint))
          this.setState({
            listSubPoint: newListSubPoint
          })
      }).catch(err => {
        console.log(err)
      })

    }

  }

  componentDidMount() {
    const query = {
      "term": {
        "idparent": this.props.id
      }
    }
    const es_index = this.props.es_info["index"]
    getKeywords(es_index, query, "point").then(res => {
      const newListPoint = res.map(item => item.key)
      if (JSON.stringify(this.state.listPoint) !== JSON.stringify(newListPoint))
        this.setState({
          listPoint: newListPoint
        })

    }).catch(err => {
      console.log(err)

    })
    const query2 = {
      "term": {
        "idparent": this.props.id
      }
    }
    getKeywords(es_index, query2, "subpoint").then(res => {
      const newListSubPoint = res.map(item => item.key)
      if (JSON.stringify(this.state.listSubPoint) !== JSON.stringify(newListSubPoint))
        this.setState({
          listSubPoint: newListSubPoint
        })
    }).catch(err => {
      console.log(err)
    })
  }

  render() {
    const { size } = this.props;
    const { from, key, anchorEl, idparent, listPoint, listSubPoint } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "transitions-popper" : undefined;
    return (
      <ReactiveBase
        app={this.props.es_info["index"]}
        url={this.props.es_info["host"]}
        credentials={this.props.es_info["credentials"]}
        className="search-component"
        key={key}
      >
        <ReactiveList
          componentId="LinkResults"
          className="results-lists"
          dataField="ordresequence"
          sortBy="asc"
          from={from}
          size={size}
          defaultQuery={() => {
            return {
              from: from, size: size, sort: [{ "ordresequence": "asc" }], "query": {
                "match_phrase": {
                  "idparent": idparent
                }
              }
            }
          }}
          pagination={false}
          infiniteScroll={false}
          showResultStats={true}

          react={{
            and: ['idParent', 'textSearch', 'speaker', 'rangeDuree', "point", "subpoint"]
          }}
          render={({ loading, error, data }) => {
            if (loading) {
              return null;
            }
            if (error) {
              return null;
            }
            this.props.updatePlaylist(data);
            return null;
          }}
          renderResultStats={function (stats) {
            this.props.handleUpdateCount(stats.numberOfResults)
            return ''
            // return `${stats.numberOfResults} extraits correspondent à votre recherche`;
          }.bind(this)}
          renderNoResults={function () { return 'Aucun résultat ne correspond à cette recherche.' }}
          innerClass={{
            resultsInfo: "resultStats",
            sortOptions: "sortOptions",
            pagination: "pagination",
            noResults: "noResults"
          }}
        />


        <ReactiveComponent
          componentId="idParent"
          customQuery={props => ({
            "from": from,
            "size": size,
            "query": {
              "match_phrase": {
                "idparent": idparent
              }
            }
          })}
          defaultQuery={props => ({
            "from": from,
            "size": size,
            "query": {
              "match_phrase": {
                "idparent": idparent
              }
            }
          })}
        />
        <SelectedFilters
          className="selected-filters"
          showClearAll={true}
          clearAllLabel="Tout effacer"
          components={[]}
          // onChange={this.handleSelectedFiltersChange}
          innerClass={{
            button: 'button'
          }}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <MultiDropdownRange
              componentId="rangeDuree"
              className="multi-list"
              dataField="duree"
              data={[
                { start: 0, end: 2, label: '< 2s' },
                { start: 2.1, end: 5, label: 'entre 2s et 5s' },
                { start: 5.1, end: 2510, label: '> 5s' }
              ]}
              placeholder="Durée"
              URLParams
              innerClass={{
                title: "title",
                select: "select",
                list: "list",
                count: "count",
              }}
              react={{
                and: ["textSearch", "idParent", "point", "speaker", "subpoint"],
              }}
              onValueChange={() => {
                this.setState({
                  from: 0
                })
              }}
            />
          </Grid>
          <Grid item xs={4} >
            <Box display="flex" className="containerSelectMode" >
              <Box className="toto">
                <DataSearch
                  componentId="textSearch"
                  className="text-search"
                  dataField={["textAll"]}
                  placeholder="recherchez un mot, une expression ..."
                  autosuggest={false}
                  iconPosition="right"
                  showFilter={true}
                  customQuery={this.queryBuilderFunc}
                  filterLabel="mots"
                  URLParams
                  showClear
                  // customHighlight={(props) => ({
                  //   highlight: {
                  //     order: "score",
                  //     fields: {
                  //       textAll: {
                  //         type: "unified",
                  //       },
                  //     },
                  //     pre_tags: [""],
                  //     post_tags: [""],
                  //     number_of_fragments: 4,
                  //     fragment_size: 20,
                  //     fragmenter: "span",
                  //     boundary_scanner: "word",
                  //     boundary_scanner_locale: "fr-FR",
                  //   },
                  // })}

                  // render={({
                  //   loading,
                  //   error,
                  //   data,
                  //   rawData,            // unmodified suggestions from Elasticsearch
                  //   aggregationData,
                  //   value,
                  //   downshiftProps: { isOpen,
                  //     getItemProps,
                  //     highlightedIndex },
                  // }) => {
                  //   if (loading) {
                  //     return <div>Fetching Suggestions...</div>
                  //   }
                  //   if (error) {
                  //     return (
                  //       <div>
                  //         Something went wrong! Error details {JSON.stringify(error)}
                  //       </div>
                  //     )
                  //   }
                  //   if (!rawData) {
                  //     return null;
                  //   }

                  //   console.log("rawData :", rawData)
                  //   console.log("aggregationData :", aggregationData)
                  //   console.log("data :", data)
                  //   const data_ = [...rawData["hits"]["hits"]];
                  //   if (data_.length > 0) {

                  //     const myData = data_.map((suggestion) => suggestion["highlight"]["textAll"].map((item) => item)
                  //     );

                  //     const lastIndex = value.lastIndexOf(" ");
                  //     const dataUnique = [...new Set(myData.flat())];
                  //     return (isOpen && Boolean(value.length) && Boolean(dataUnique.length)) ? (
                  //       <div className="list">
                  //         {dataUnique.slice(0, 5).map((suggestion, index) => (
                  //           <AutosugestionTypo
                  //             id={'suggestion-' + index}
                  //             key={suggestion}
                  //             {...getItemProps({ item: { value: value.substring(0, lastIndex) + " " + suggestion } })}
                  //           >
                  //             {suggestion}
                  //           </AutosugestionTypo>
                  //         ))}
                  //       </div>
                  //     ) : null;
                  //   }
                  //   else {
                  //     return null
                  //   }
                  // }}
                  // onValueChange={
                  //   function (value) {
                  //     console.log("current value: ", value)
                  //     this.props.updateSearchValue(value)
                  //   }.bind(this)
                  // }
                  //  highlight

                  innerClass={{
                    title: "title",
                    input: "input",
                    list: "list",
                  }}
                // onValueChange={() => {
                //   this.setState({
                //     from: 0
                //   })
                // }}

                />
              </Box>
              <Box>
                <IconButton aria-describedby={id} className="help-button" onClick={this.handleHelpOpen} >
                  <MdHelp color="#ececf5" />
                </IconButton>

                <Popper
                  elevation={0}
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  placement="right-start"
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <div className="tooltip">
                        <IconButton
                          id="closeToolTip"
                          onClick={this.handleHelpClose}
                          title="Fermer l'aide à la recherche"
                          className="close-tootlip"
                        >
                          <MdClose size={20} />
                        </IconButton>
                        <p className="popperTitle">Aide à la recherche :</p>
                        <p>
                          Vous pouvez saisir un mot ou plusieurs mots séparés par
                          des espaces. Dans ce cas, la recherche va retourner toutes
                          les séquences contenant l’un ou l’autre de ces mots
                          (équivalent de l’opérateur OR ou du caractère |). La
                          recherche est insensible à la casse.
                        </p>
                        <p>
                          Vous avez également la possibilité d'utiliser le caractère "/" pour combiner les opérateurs AND et OR.
                          Les termes à gauche du caractère "/" seront considérés comme obligatoires, tandis que ceux à droite seront facultatifs.
                        </p>
                        <p>
                          Si vous cherchez une expression précise, les caractères
                          " " permettent de rechercher exactement une suite de
                          mots.
                        </p>

                      </div>
                    </Fade>
                  )}

                </Popper>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <MultiDropdownList
              componentId="speaker"
              className="multi-list"
              placeholder="Choix du locuteur"
              dataField="listloc"
              showCount={true}
              autosuggest={true}
              showSearch={false}
              searchPlaceholder="Chercher un locuteur"
              showFilter={true}
              filterLabel="locuteur"
              URLParams
              size={500}
              react={{
                and: ["textSearch", "idParent", "rangeDuree", "point", "subpoint"],
              }}
              innerClass={{
                title: "title",
                select: "select",
                list: "list",
                count: "count",
              }}
              onValueChange={() => {
                this.setState({
                  from: 0
                })
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {listPoint.length > 0 &&
              <MultiDropdownList
                componentId="point"
                className="multi-list"
                placeholder="Choix du point"
                dataField="point"
                showCount={true}
                sortBy="count"
                autosuggest={true}
                showSearch={true}
                searchPlaceholder=""
                showFilter={true}
                filterLabel="point"
                URLParams
                size={500}
                react={{
                  and: ["textSearch", "idParent", "rangeDuree", "speaker", "subpoint"],
                }}
                innerClass={{
                  title: "title",
                  select: "select",
                  list: "list",
                  count: "count",
                }}
                onValueChange={() => {
                  this.setState({
                    from: 0
                  })
                }}
                transformData={(data) => {
                  console.log(data)
                  const result = data.sort((a, b) => {
                    return listPoint.indexOf(a.key) - listPoint.indexOf(b.key);
                  });
                  console.log(result)

                  return result
                }}

              />
            }
          </Grid>
          <Grid item xs={4}>
            {listSubPoint.length > 0 && <MultiDropdownList
              componentId="subpoint"
              className="multi-list"
              placeholder="Choix du sous-point"
              dataField="subpoint"
              showCount={true}
              autosuggest={true}
              showSearch={true}
              searchPlaceholder=""
              showFilter={true}
              filterLabel="point"
              URLParams
              size={500}
              react={{
                and: ["textSearch", "idParent", "rangeDuree", "speaker", "point"],
              }}
              innerClass={{
                title: "title",
                select: "select",
                list: "list",
                count: "count",
              }}
              onValueChange={() => {
                this.setState({
                  from: 0
                })
              }}
              transformData={(data) => {
                const result = data.sort((a, b) => {
                  return listSubPoint.indexOf(a.key) - listSubPoint.indexOf(b.key);
                });

                return result
              }}

            />}
          </Grid>

        </Grid>












      </ReactiveBase>
    );
  }
}
