import React, { Component } from 'react';
import { MdKeyboardBackspace, MdSave } from 'react-icons/md';
import '../../styles/EditResume.css';
import { getParamConfig, updateSeq } from '../../utils/functions';
import PropTypes from 'prop-types';
import Resizable from 'react-resizable-layout';
import { cn } from '../../utils/cn';
import { getHitsFromQuery } from '../../utils/functions';
import SampleSeparator from './annotation/SampleSeparator';
import axios from 'axios';
import { Box, Button, CircularProgress, FormControlLabel, Grid, MenuItem, Select, Switch, TextareaAutosize } from '@mui/material';


function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}


Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

class EditResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showNote: false,
            idx: 0,
            videoIndex: "",
            loading: false
        };
        this.handleShowNote = this.handleShowNote.bind(this)
        this.handleChangePoint = this.handleChangePoint.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleUpdateText = this.handleUpdateText.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleExport = this.handleExport.bind(this)
    }


    handleBack = () => {
        document.location.href = '/mySpace'
    }

    handleShowNote() {
        this.setState({ showNote: !this.state.showNote })
    }


    handleUpdateText(event) {
        const { data, idx } = this.state;
        let { synthpoint } = data;
        synthpoint[idx]["synthese"] = event.target.value
        this.setState({
            data: { ...data, synthpoint: synthpoint }
        })

    }

    async handleExport() {
        try {
            const params = new URLSearchParams(document.location.search);
            const id = params.get('i')
            const videoIndex = this.state.videoIndex
            const api = getParamConfig("api") + "/util/exportSynthesis";
            this.setState({
                loading: true
            })
            const response = await axios.post(api, { id: id, es_index: videoIndex }, {
                responseType: 'blob', // Tell axios to expect binary data
            });

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', id + '.doc');
            document.body.appendChild(link);
            link.click();

            this.setState({
                loading: false,
            })
        } catch (error) {
            console.error('Error generating docx:', error);
        }
    }

    handleUpdate() {
        const params = new URLSearchParams(document.location.search);
        const id = params.get('i');
        const { data, videoIndex } = this.state
        const req = {
            es_index: videoIndex,
            id: id,
            data: JSON.stringify({ synthpoint: data["synthpoint"] }),
        };
        updateSeq(req).then((res) => {
            console.log(res)
        }).catch(err => console.log(err));
    }

    handleChangePoint(event) {

        this.setState({ idx: event.target.value })
    }

    componentDidUpdate(prevProps, prevState) {
    }


    componentDidMount() {
        const params = new URLSearchParams(document.location.search);
        const id = params.get('i');
        const indice = params.get('c');
        const { es_host, es_credential } = getParamConfig();
        getHitsFromQuery(
            es_host,
            "appconfig",
            es_credential,
            { size: 500, query: { match_all: {} } }
        )
            .then((res) => {
                const config = res.find((item) => item._source["displayName"] === indice);

                if (config) {
                    const videoIndex = config._source["overviewIndex"];
                    const query3 = {
                        "term": {
                            "_id": id
                        }

                    }
                    getHitsFromQuery(
                        es_host,
                        videoIndex,
                        es_credential,
                        { size: 1, query: query3 }
                    )
                        .then((res) => {
                            if (res.length === 1 && res[0]._source) {
                                this.setState({
                                    data: res[0]._source,
                                    videoIndex: videoIndex
                                })
                            }
                        })
                        .catch((e) => {
                            console.log("error :", e);
                        })
                }
            }).catch(err => console.log(err))



    }

    componentWillUnmount() {
        this.playerRef = null

    }


    render() {

        const { data, showNote, idx, loading } = this.state;
        const initX = window.screen.width / 2;
        const rowHeight = (window.innerHeight * 16) / 850
        return (
            <div className='div-list'>
                <Resizable axis={'x'} initial={initX} min={400} >
                    {({ position, separatorProps }) => (
                        <div id="wrapper" style={{ display: 'flex', height: '95vh', overflow: 'hidden' }}>
                            <div id="left-block" style={{ width: showNote ? position : "100%" }} className={cn('sample-box', 'sample-box--blue')}>
                                <div className='header'>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Button className={"bt-back"} startIcon={<MdKeyboardBackspace />} onClick={this.handleBack} >
                                                Retour
                                            </Button>
                                        </Grid>
                                        {data.note && <Grid item xs={4}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={showNote} />}
                                                label="Note"
                                                labelPlacement="top"
                                                className='reformulation-switch'
                                                onChange={this.handleShowNote}
                                            />
                                        </Grid>}
                                        <Grid item xs={4}>
                                            <Button className='button plain primaryMain first' onClick={this.handleExport}>Export word</Button>
                                            {loading && <CircularProgress size={24} className={"buttonprogress"} />}
                                        </Grid>
                                    </Grid>

                                </div >
                                <div className='main'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                labelId="simple-select-point"
                                                id="simple-select-point"
                                                value={idx}
                                                label="Point"
                                                onChange={this.handleChangePoint}
                                            >
                                                {data.synthpoint && data.synthpoint.map((item, i) => (<MenuItem value={i}>{i + 1}) {item.point}</MenuItem>))}

                                            </Select>
                                        </Grid>

                                        {data.synthpoint && <Grid item xs={12}>
                                            <span className='text-label'> Synthèse : </span>
                                            <TextareaAutosize
                                                id="standard-multiline-text"
                                                className={false ? "text text-updated" : 'text'}
                                                maxRows={rowHeight}
                                                defaultValue={data.synthpoint[idx].synthese}
                                                value={data.synthpoint[idx].synthese}
                                                onChange={this.handleUpdateText}
                                                placeholder='Synthèse'

                                            />
                                        </Grid>}
                                        <Grid item xs>

                                            <Button
                                                variant="contained"
                                                className='button plain primaryMain first'
                                                startIcon={<MdSave />}
                                                onClick={this.handleUpdate}
                                            >
                                                Mise à jour
                                            </Button>


                                        </Grid>
                                    </Grid>
                                </div>

                                <footer className='footer'>



                                </footer>

                            </div>
                            {showNote &&
                                [
                                    <SampleSeparator id="splitter" {...separatorProps} />,
                                    <div id="right-block" className={cn('sample-box', 'sample-box--red')} style={{
                                        "width": `calc(100% - ${position}px)`

                                    }}>
                                        <iframe src={data.note} width="100%" height={0.7 * window.screen.height} title="tutoriel" />
                                    </div>]}
                        </div >
                    )}
                </Resizable>
            </div >
        );
    }
}


export default EditResume

