import React, { useState } from 'react';
import { Container, Paper, Typography } from '@mui/material';
import FileUploader from './FileUploader';
import AudioFileList from './AudioFileList';
import '../../../styles/AudioUpload.css';

function AudioUpload({ handleNext }) {
    const [audioFiles, setAudioFiles] = useState([]);

    const handleFilesSelected = (newFiles) => {
        setAudioFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    const handleDelete = (fileId) => {
        setAudioFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
    };

    const handleReorder = (sourceIndex, destinationIndex) => {
        const newFiles = Array.from(audioFiles);
        const [removed] = newFiles.splice(sourceIndex, 1);
        newFiles.splice(destinationIndex, 0, removed);
        setAudioFiles(newFiles);
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }} >
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#d8d8d8' }} className='paper'>
                <Typography variant="h5" component="h5" gutterBottom align="center">
                    Téléchargez vos enregistrements audio ou vidéo
                </Typography>

                <FileUploader onFilesSelected={handleFilesSelected} />

                <AudioFileList
                    files={audioFiles}
                    onDelete={handleDelete}
                    onReorder={handleReorder}
                    handleNext={handleNext}
                />
            </Paper>
        </Container>
    );
}



export default AudioUpload;