import React, { Component } from 'react';
import TableData from './TableData';
import Annotation from './annotation/Annotation';
import { getHitsFromQuery, getParamConfig, getUserToken } from '../../utils/functions';


class MySpace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edited: false,
            id: "",
            appconfig: [],
            note: null
        }
        this.handleEdit = this.handleEdit.bind(this)

    }

    handleEdit(id, note) {
        this.setState({
            edited: id !== "",
            id: id, 
            note: note
        })

    }

    componentDidMount() {
        const paramConfig = getParamConfig()
        const userToken = getUserToken()
        getHitsFromQuery(
            paramConfig["es_host"],
            "appconfig",
            paramConfig["es_credential"],
            { size: 500, query: { match_all: {} } }
        )
            .then((res) => {
                let appconfig = []
                const indexDemo = userToken && userToken["indexDemo"] ? Array.isArray(userToken["indexDemo"]) ? userToken["indexDemo"] : [userToken["indexDemo"]] : null
                if (indexDemo) {

                    for (const item of res) {

                        if (indexDemo.includes(item._source["name"]))
                            appconfig.push(item._source)

                    }
                } else {
                    appconfig = res.map((item) => item._source);
                }

                this.setState({
                    appconfig: appconfig

                });

            })
            .catch((e) => {
                console.log("error :", e);
            });
    }



    render() {
        const { edited, id, appconfig, note } = this.state;
        return (
            <div>
                {edited ? <Annotation id={id} handleEdit={this.handleEdit} appConfig={appconfig} note={note}/> : <TableData handleEdit={this.handleEdit} appConfig={appconfig} />}
            </div>

        );
    }
}

export default MySpace;