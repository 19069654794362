import React, { Component } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import '../../../styles/Annotation.css';
import SearchComponent from './secondScreen/SearchComponent';
import { array_equal, createSrt, formatedTime, getCountByQuery, getHitsFromQuery, getParamConfig } from '../../../utils/functions';
import AnnotationComponent from './secondScreen/AnnotationComponent';
import PropTypes from 'prop-types';
import SequenciaPlayer from '@abreport-player/web';
import axios from 'axios';
import Resizable from 'react-resizable-layout';
import SampleSeparator from './SampleSeparator';
import { cn } from '../../../utils/cn';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Paper, Switch, TablePagination, FormLabel, Checkbox, FormControl, FormGroup } from '@mui/material';


function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2b2a33' : '#2b2a33'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}


Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

class Annotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playlist: [],
            searchValue: "",
            currentIndex: 0,
            page: 0,
            rowsPerPage: 300,
            count: 10,
            key: 0,
            loading: false,
            openMenuExport: false,
            options: ["Point", "Sous-point", "Locuteur"],
            timecode: [],
            listPoint: [],
            listSubPoint: [],
            showNote: false,
            retinIndex: "",
            videoIndex: "",
            note: "",
            type_reformulation: ""
        };
        this.playerRef = React.createRef();
        this.updatePlaylist = this.updatePlaylist.bind(this);
        this.updateSearchValue = this.updateSearchValue.bind(this)
        this.updateSequence = this.updateSequence.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleUpdateCount = this.handleUpdateCount.bind(this)
        this.handleNextClip = this.handleNextClip.bind(this)
        this.deleteSequence = this.deleteSequence.bind(this)
        this.exportSrt = this.exportSrt.bind(this)
        this.exportDocx = this.exportDocx.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleDialogOpen = this.handleDialogOpen.bind(this)
        this.handleShowNote = this.handleShowNote.bind(this)
    }

    handleShowNote() {
        this.setState({ showNote: !this.state.showNote })
    }


    updateSearchValue(value) {
        this.setState({ searchValue: value })
    }

    updatePlaylist(hits, isUpdated) {
        const newPlaylist = hits.map((item) => {
            const source = item._source || item;
            const videoObject = {
                _source: source,
                url: "",
                startTime: null,
                endTime: null,
                title: "",
                isExtendable: false,
                pbInfo: "",
            };

            if (source.linkhls) {
                const [url, time] = source.linkhls.split("#t=");
                const [startTime, endTime] = time.split(",").map(parseFloat);

                videoObject.url = url;
                videoObject.startTime = Math.floor(startTime);
                videoObject.endTime = Math.ceil(endTime) + 0.5;
                videoObject.duree = parseFloat(videoObject.endTime - videoObject.startTime);

                for (const field of ["provenance_name"]) {
                    if (source[field]) {
                        videoObject.title += source[field] + " ";
                    }
                }

                videoObject.title += source["ordresequence"];
                videoObject.isExtendable = true;

                const formattedTime = formatedTime(source["start"])

                videoObject.pbInfo = `${formattedTime}`;
                if (source["listloc"]) {
                    const speaker = source["listloc"].slice(0, 22)
                    videoObject.pbInfo += source["listloc"].length > 22 ? ` | ${speaker} ...` : ` | ${speaker}`;

                }

            }

            return videoObject;
        });

        if (!array_equal(newPlaylist, this.state.playlist)) {

            this.setState({
                playlist: newPlaylist,
                page: isUpdated ? 0 : this.state.page,
            });
        }
    }


    handleNextClip() {
        const { page, playlist, count, rowsPerPage } = this.state
        const currentIndex = this.playerRef.current?.getCurrentVideoIndex()
        const endIndex = count === (page * rowsPerPage) + playlist.length
        this.setState({
            currentIndex: currentIndex === playlist.length - 1 ? 0 : currentIndex + 1,
            page: currentIndex === playlist.length - 1 ? endIndex ? 0 : page + 1 : page
        })
    }

    updateSequence(sequence) {
        if (this.playerRef.current?.isPlaying())
            this.playerRef.current?.pause()
        let playlist = this.state.playlist.slice()
        const currentIndex = this.playerRef.current?.getCurrentVideoIndex()
        const { page, rowsPerPage, count } = this.state
        const endIndex = count === (page * rowsPerPage) + playlist.length
        playlist[currentIndex] = sequence
        this.setState({
            currentIndex: currentIndex === playlist.length - 1 ? 0 : currentIndex + 1,
            key: this.state.key + 1,
            page: currentIndex === playlist.length - 1 ? endIndex ? 0 : page + 1 : page
        })
    }

    deleteSequence(id) {
        if (this.playerRef.current?.isPlaying())
            this.playerRef.current?.pause()

        const idx = this.state.playlist.findIndex(item => item._source._id === id)
        let playlist = this.state.playlist.slice()
        if (idx !== -1) {
            playlist.splice(idx, 1)
            const currentIndex = this.playerRef.current?.getCurrentVideoIndex()
            this.setState({
                currentIndex: currentIndex,
                key: this.state.key + 1
            })
        }
    }


    exportSrt() {
        const curSequence = this.playerRef.current?.playlistManager.currentVideo

        const video_index = this.state.videoIndex
        const req = {
            es_index: video_index,
            id: curSequence._source.idparent
        }

        createSrt(req).then(res => {
            if (res.status === 200) {
                const text = res.res.text
                // Create a Blob with the file content
                const blob = new Blob([text], { type: 'text/plain' });

                // Create a download link
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = res.res.filename;

                // Append the link to the document and trigger the click event
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Remove the link from the document
                document.body.removeChild(downloadLink);
            }
        }
        ).catch(err => console.log(err))
    }


    async exportDocx() {
        try {
            const curSequence = this.playerRef.current?.playlistManager.currentVideo

            const params = new URLSearchParams(document.location.search);
            const id = params.get('i')
            const retinIndex = this.state.retinIndex
            const { options, timecode } = this.state
            const api = getParamConfig("api") + "/util/createDocx";
            this.setState({
                loading: true
            })
            const response = await axios.post(api, { id: id, es_index: retinIndex, options: options.join(","), timecode: timecode.join(",") }, {
                responseType: 'blob', // Tell axios to expect binary data
            });

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', curSequence._source.provenance_name + '.doc');
            document.body.appendChild(link);
            link.click();
            // const api2 = getParamConfig("api") + "/util/createDocxDraft";
            // const response2 = await axios.post(api2, { id: this.props.id, es_index: retinIndex, options: options.join(","), timecode: timecode.join(",") }, {
            //     responseType: 'blob', // Tell axios to expect binary data
            // });

            // const downloadUrl2 = window.URL.createObjectURL(new Blob([response2.data]));
            // const link2 = document.createElement('a');
            // link2.href = downloadUrl2;
            // link2.setAttribute('download', provenance_name + '_draft.docx');
            // document.body.appendChild(link2);
            // link2.click();
            this.setState({
                loading: false,
                openMenuExport: false
            })
        } catch (error) {
            console.error('Error generating docx:', error);
        }
    }

    handleChangePage(event, newPage) {
        this.setState({
            page: newPage,
            currentIndex: 0
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

    handleBack = async () => {
        document.location.href = '/mySpace'
    }

    handleUpdateCount(count) {
        if (count !== this.state.count) {
            this.setState({
                count: count,
                page: 0
            })
        }
    }

    handleCheckboxChange = (event, type) => {
        const { value, checked } = event.target;
        const { options, timecode } = this.state
        if (type === 'options')
            this.setState({
                options: checked ? [...options, value] : options.filter((option) => option !== value)
            })
        else {
            if (value === "0") {
                this.setState({
                    timecode: checked ? [] : []
                })
            } else
                this.setState({
                    timecode: checked ? [...timecode, value] : timecode.filter((option) => option !== value)
                })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { currentIndex, count, showNote } = this.state

        if (prevState.count !== count) {
            this.setState({
                page: 0
            })
        }

        if (prevState.showNote === showNote)
            this.playerRef.current?.setClip(currentIndex)

        // const { listPoint, listSubPoint } = this.state
        // let query = {
        //     "term": {
        //         "idparent": {
        //             "value": id
        //         }
        //     }
        // }
        // getKeywords(retinIndex, query, "point").then(async res => {
        //     const newListPoint = res.map(item => item.key)
        //     if (JSON.stringify(listPoint) !== JSON.stringify(newListPoint)) {
        //         this.setState({
        //             listPoint: newListPoint
        //         })
        //     }
        //     for (let i = 0; i < newListPoint.length; i++) {
        //         query = {
        //             "bool": {
        //                 "must": [
        //                     { "term": { "idparent": id } },
        //                     {
        //                         "term": { "point": newListPoint[i] }

        //                     }]
        //             }
        //         }
        //         const res = await getKeywords(retinIndex, query, "subpoint")


        //         const newListSubPoint = res.map(item => item.key)

        //         if (JSON.stringify(listSubPoint[i]) !== JSON.stringify(newListSubPoint)) {
        //             listSubPoint[i] = newListSubPoint
        //             this.setState({
        //                 listSubPoint: listSubPoint
        //             })
        //         }


        //     }

        // }).catch(err => {
        //     console.log(err)
        // })
    }

    handleDialogClose = () => {
        this.setState({ openMenuExport: false })
    }

    handleDialogOpen = () => {
        this.setState({ openMenuExport: true })
    }

    /*************  ✨ Codeium Command ⭐  *************/
    componentDidMount() {
        const params = new URLSearchParams(document.location.search);
        const id = params.get('i');
        const indice = params.get('c');
        const { es_host, es_credential } = getParamConfig();
        getHitsFromQuery(
            es_host,
            "appconfig",
            es_credential,
            { size: 500, query: { match_all: {} } }
        )
            .then((res) => {
                const config = res.find((item) => item._source["displayName"] === indice);

                if (config) {
                    const retinIndex = config._source["index"];
                    const query = {
                        "query": {
                            "match_phrase": {
                                "idparent": id
                            }
                        }
                    }
                    getCountByQuery(retinIndex, query).then(res => {
                        if (res.status === 200) {
                            this.setState({
                                count: res.count
                            })
                        }
                    }).catch(err => console.log(err))

                    // const { listPoint, listSubPoint } = this.state
                    // let query2 = {
                    //     "term": {
                    //         "idparent": id
                    //     }

                    // }
                    // getKeywords(retinIndex, query2, "point").then(async res => {
                    //     const newListPoint = res.map(item => item.key)
                    //     if (JSON.stringify(listPoint) !== JSON.stringify(newListPoint)) {
                    //         this.setState({
                    //             listPoint: newListPoint
                    //         })
                    //     }
                    //     for (let point of newListPoint) {
                    //         query2 = {
                    //             "bool": {
                    //                 "must": [
                    //                     { "term": { "idparent": id } },
                    //                     {
                    //                         "term": { "point": point }

                    //                     }]
                    //             }
                    //         }
                    //         const res = await getKeywords(retinIndex, query2, "subpoint")


                    //         const newListSubPoint = res.map(item => item.key)
                    //         this.setState({
                    //             listSubPoint: [...listSubPoint, newListSubPoint]
                    //         })


                    //     }
                    // }).catch(err => {
                    //     console.log(err)
                    // })
                    const videoIndex = config._source["overviewIndex"];
                    const query3 = {
                        "term": {
                            "_id": id
                        }

                    }
                    getHitsFromQuery(
                        es_host,
                        videoIndex,
                        es_credential,
                        { size: 1, query: query3 }
                    )
                        .then((res) => {
                            if (res.length === 1 && res[0]._source) {
                                this.setState({
                                    note: res[0]._source["note"] ? res[0]._source["note"] : "",
                                    type_reformulation: res[0]._source["type_reformulation"] ? res[0]._source["type_reformulation"] : "",
                                })
                            }
                        })
                        .catch((e) => {
                            console.log("error :", e);
                        })

                    this.setState({
                        retinIndex: retinIndex,
                        videoIndex: videoIndex
                    })
                }

            })
            .catch((e) => {
                console.log("error :", e);
            });




    }

    componentWillUnmount() {
        this.playerRef = null

    }


    render() {
        const params = new URLSearchParams(document.location.search);
        const id = params.get('i');
        const paramConfig = getParamConfig();
        const { page, rowsPerPage, count, key, loading, options, timecode, showNote, retinIndex, note, type_reformulation } = this.state;
        const from = page * rowsPerPage;
        const initX = window.screen.width / 2
        return (
            retinIndex &&
            <div className='annotation'>
                <Resizable axis={'x'} initial={initX} min={400} >
                    {({ position, separatorProps }) => (
                        <div id="wrapper" style={{ display: 'flex', height: '95vh', overflow: 'hidden' }}>
                            <div id="left-block" style={{ width: showNote ? position : "100%" }} className={cn('sample-box', 'sample-box--blue')}>
                                <div className='header'>

                                    <Paper className='search'>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <Item>
                                                {note &&
                                                    <FormControlLabel
                                                        control={<Switch color="primary" checked={showNote} />}
                                                        label="Prise de note"
                                                        labelPlacement="top"
                                                        className='note-switch'
                                                        onChange={this.handleShowNote}
                                                    />
                                                }
                                            </Item>
                                            <Item >
                                                <SearchComponent es_info={
                                                    {
                                                        index: retinIndex,
                                                        host: paramConfig["es_host"],
                                                        credentials: paramConfig["es_credential"],
                                                    }
                                                }
                                                    updatePlaylist={this.updatePlaylist}
                                                    updateSearchValue={this.updateSearchValue}
                                                    id={id}
                                                    from={from}
                                                    size={rowsPerPage}
                                                    handleUpdateCount={this.handleUpdateCount}
                                                    refreshKey={key}
                                                />
                                            </Item>
                                            <Item>
                                                <div className={"export"}>
                                                    <Button className='button plain primaryMain first' onClick={this.handleDialogOpen}>Export word</Button>
                                                    {loading && <CircularProgress size={24} className={"buttonprogress"} />}
                                                </div>

                                            </Item>
                                        </Box>

                                    </Paper>
                                </div >
                                <div className='mains' style={{ overflowY: 'auto', height: "calc(80vh - 180px)" }}>
                                    <AnnotationComponent
                                        searchValue={""}
                                        playerRef={this.playerRef}
                                        es_index={retinIndex}
                                        updateSequence={this.updateSequence}
                                        id={id}
                                        handleNextClip={this.handleNextClip}
                                        deleteSequence={this.deleteSequence}
                                        type_reformulation={type_reformulation}
                                    />
                                </div>

                                <footer className='footer'>
                                    <SequenciaPlayer
                                        height={120}
                                        width={"95%"}
                                        ref={this.playerRef}
                                        src={this.state.playlist}
                                        infoField={"title"}
                                    />
                                    <TablePagination
                                        className='pagination'
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={this.handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[{ value: 100, label: 100 }, { value: 200, label: 200 }, { value: 300, label: 300 }]}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                        labelRowsPerPage={"Pagination"}
                                    />

                                </footer>

                                <Dialog
                                    open={this.state.openMenuExport}
                                    onClose={this.handleDialogClose}
                                    aria-labelledby="dialog-export"
                                >
                                    <DialogTitle id="dialog-delete" className='dialog-title'>
                                        <MdCloudDownload /> Télécharger le compte rendu
                                        <Divider className='divider' />

                                    </DialogTitle>
                                    <DialogContent>
                                        <FormControl component="fieldset">

                                            <FormLabel component="legend">Choisissez si vous souhaitez faire apparaître les options suivantes :</FormLabel>
                                            <FormGroup className='option-form' aria-label="position" row onClick={(e) => this.handleCheckboxChange(e, "options")}
                                            >
                                                <FormControlLabel
                                                    value="Point"
                                                    control={<Checkbox checked={options.includes("Point")} />}
                                                    label="Point"
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="Sous-point"
                                                    control={<Checkbox checked={options.includes("Sous-point")} />}
                                                    label="Sous-point"
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="Locuteur"
                                                    control={<Checkbox checked={options.includes("Locuteur")} />}
                                                    label="Locuteur"
                                                    labelPlacement="top"
                                                />
                                            </FormGroup>
                                            <FormLabel component="legend">Choisissez si vous voulez voir apparaitre les time codes :</FormLabel>
                                            <FormGroup className='timecode-form' aria-label="position" row onClick={(e) => this.handleCheckboxChange(e, "timecode")} >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Checkbox checked={timecode.length === 0} />}
                                                    label="Aucun time code"
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="Point"
                                                    control={<Checkbox checked={timecode.includes("Point")} />}
                                                    label="Point"
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="Sous-point"
                                                    control={<Checkbox checked={timecode.includes("Sous-point")} />}
                                                    label="Sous-point"
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="Locuteur"
                                                    control={<Checkbox checked={timecode.includes("Locuteur")} />}
                                                    label="Locuteur"
                                                    labelPlacement="top"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <br />

                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={this.handleDialogClose}
                                            className="button plain primaryMain"
                                        >
                                            Annuler
                                        </Button>
                                        <Button
                                            onClick={this.exportDocx}
                                            className="button plain primaryMain"
                                        >
                                            Télecharger
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            {showNote &&
                                [
                                    <SampleSeparator id="splitter" {...separatorProps} />,
                                    <div id="right-block" className={cn('sample-box', 'sample-box--red')} style={{
                                        "width": `calc(100% - ${position}px)`

                                    }}>
                                        <iframe src={note} width="100%" height={0.7 * window.screen.height} title="tutoriel" />
                                    </div>]}
                        </div >
                    )}
                </Resizable>
            </div >
        );
    }
}


export default Annotation

