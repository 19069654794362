import axios from 'axios';
import jwt_decode from "jwt-decode";

export function getParamConfig(param) {
    let config = {};
    config['es_host'] = process.env.REACT_APP_HOST_ES;
    config['es_credential'] = process.env.REACT_APP_CREDENTIALS_ES;
    config['web_url'] = process.env.REACT_APP_WEB_URL;
    config['api'] = process.env.REACT_APP_SEQUENCIA_API;
    if (param) {
        return config[param];
    } else {
        return config;
    }
}

// login post function
export const login = async (user) => {
    try {
        const api = getParamConfig("api");

        const res = await axios.post(api + "/auth/login", user, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });
        return res.data;
    } catch (err) {
        return err;
    }
};

// upload post function
export const upload = async (file) => {
    try {
        const api = getParamConfig("api");
        const res = await axios.post(api + '/util/upload', file, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });
        return res.data;
    } catch (err) {
        return err;
    }
};

// index post function
export const index = async (params) => {
    try {
        const api = getParamConfig("api");
        const res = await axios.post(api + '/util/index', params, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });
        return res.data;
    } catch (err) {
        return err;
    }
};

// update index post function
export const updateIndex = async (params) => {
    try {
        const api = getParamConfig("api");
        const res = await axios.post(api + '/util/updateIndex', params, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });
        console.log(res)
        return res.data;
    } catch (err) {
        return err;
    }
};


// update index post function
export const generatePVDigital = async (params) => {
    try {
        const api = getParamConfig("api");
        const res = await axios.post(api + '/util/generatePVDigital', params, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });
        console.log(res)
        return res.data;
    } catch (err) {
        return err;
    }
};


// Get user token
export function getUserToken() {
    const token = getWithExpiry("usertoken");
    if (token) {
        const decoded = jwt_decode(token);
        return decoded;
    } else {
        return null;
    }
}

// Get total hits from host
// export function getHitsFromQuery(host, query) {
//     return new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', host + '/_search?pretty');
//         xhr.setRequestHeader('Content-type', 'application/json');
//         xhr.send(query);
//         xhr.addEventListener('load', () => {
//             const response = JSON.parse(xhr.responseText);
//             const output = response.hits ? response.hits.hits : [];
//             resolve(output);
//         });
//         xhr.addEventListener('error', () => {
//             const error = JSON.parse(xhr.responseText);
//             reject(error);
//         });
//     });
// }

// Get all indices in Elasticsearch
export function getAllindices(host, credentials) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let url = host + "/_cat/indices";
        xhr.open("GET", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(credentials));
        xhr.send();
        xhr.addEventListener("load", () => {
            const response = xhr.responseText;
            const separateLines = response.split(/\r?\n|\r|\n/g);

            let indices = []
            separateLines.forEach(line => {
                if (line) {
                    const items = line.split("open ")
                    const indice = items[1].split(" ")
                    if (indice[0].startsWith("video-"))
                        indices.push(indice[0])
                }
            })
            resolve(indices);
        });
        xhr.addEventListener("error", () => {
            try {
                const error = JSON.parse(xhr.responseText);
                reject(error);
            } catch (e) { reject(xhr.responseText); }
        });
    });
}


// Get all speaker of pecific id doc
export function getKeywords(index, query, field) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const { es_host, es_credential } = getParamConfig()
        const url = es_host + "/" + index + "/_search?pretty";
        const req = {
            "size": 0,
            query,
            "aggs": {
                "unique_keywords": {
                    "terms": {
                        "field": field,
                        "size": 500
                    },
                    "aggs": {
                        "latestOrder": {
                            "max": {
                                "field": "ordresequence"
                            }
                        },
                        "bucket_sort_order": {
                            "bucket_sort": {
                                "sort": {
                                    "latestOrder": {
                                        "order": "asc"
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }

        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(es_credential));
        xhr.send(JSON.stringify(req));
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);
            let keyword = response.aggregations ? response.aggregations.unique_keywords ? response.aggregations.unique_keywords.buckets : [] : [];
            keyword = keyword.filter(item => item.key);
            resolve(keyword);
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}

// Get total hits from host
export function getHitsFromQuery(host, index, credentials, query) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let url = host + "/" + index + "/_search?pretty";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(credentials));
        xhr.send(JSON.stringify(query));
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);
            const output = response.hits ? response.hits.hits : [];
            resolve(output);
        });
        xhr.addEventListener("error", () => {
            const error = xhr.responseText;
            reject(error);
        });
    });
}


// Delete by query 
export function deleteByQuery(host, index, credentials, query) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let url = host + "/" + index + "/_delete_by_query";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(credentials));
        xhr.send(JSON.stringify(query));
        xhr.addEventListener("load", () => {
            resolve(xhr.status);
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}

// Update by query 
export function updateByQuery(index, query) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const { es_host, es_credential } = getParamConfig()
        let url = es_host + "/" + index + "/_update_by_query";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(es_credential));
        xhr.send(JSON.stringify(query));
        xhr.addEventListener("load", async () => {
            await sleep(1000);
            resolve(xhr.status);
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}


// Gets the number of matches for a search query.
export function getCountByQuery(index, query) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const { es_host, es_credential } = getParamConfig()

        let url = es_host + "/" + index + "/_count";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Basic " + btoa(es_credential));
        xhr.send(JSON.stringify(query));
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);
            if ("count" in response) {
                const res = { status: 200, count: response["count"] }
                resolve(res);
            }
            else {
                resolve({ status: 404, count: 0 });
            }
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}




export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

export function setWithExpiry(key, value, ttl = 3600000) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function validURL(str) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !pattern.test(str);
}


export const checkIfLogin = () => {
    !Boolean(getUserToken()) && document.location.reload(true);
};


export const isValidSHA1 = (str) => {
    const regex = new RegExp("^[a-fA-F0-9]{40}$");
    return regex.test(str);

}

export const array_equal = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
};

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function updateSeq(req) {
    try {
        const api = getParamConfig("api");
        const host = api + "/edite/updateOneSequence"
        const res = await axios.post(host, req);
        await sleep(1000);
        return res.data;
    } catch (err) {
        return err;
    }
}

// Delete sequence 
export async function deleteSeq(req) {
    try {
        const api = getParamConfig("api");
        const host = api + "/edite/deleteOneSequence"
        const res = await axios.post(host, req);
        await sleep(1000);
        return res.data;
    } catch (err) {
        return err;
    }
}


export async function createSrt(req) {
    try {
        const api = getParamConfig("api");
        const host = api + "/util/createSrt"
        const res = await axios.post(host, req);
        return res.data;
    } catch (err) {
        return err;
    }
}

//Send notification mail
export async function sendMail(req) {
    try {
        const api = getParamConfig("api");
        const host = api + "/util/sendMail"
        const res = await axios.post(host, req);
        return res.data;
    } catch (err) {
        return err;
    }
}


export function romanize(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM", "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC", "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}



export function formatedTime(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

export function isYouTubeUrl(url) {
    // Regular expression to match YouTube video URLs
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

    // Test if the URL matches the regular expression
    return youtubeRegex.test(url);
}


export function splitFilename(str) {
    const lastDotIndex = str.lastIndexOf(".");
    if (
        lastDotIndex === -1 ||
        lastDotIndex === 0 ||
        lastDotIndex === str.length - 1
    ) {
        // No extension found or it's at the beginning or end of the string
        return {
            basename: str,
            extension: "",
        };
    } else {
        return {
            basename: str.substring(0, lastDotIndex),
            extension: str.substring(lastDotIndex + 1),
        };
    }
}


// kill process by id
export async function killProcess(req) {
    try {
        const api = getParamConfig("api");
        const host = api + "/util/killProcess"
        const res = await axios.post(host, req);
        return res.data;
    } catch (err) {
        return err;
    }
}



